import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import Swal from "sweetalert2";
import languages from "../../forms/languages";
import api from "../../helpers/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddFileTranslation = (props) => {
  const { fileType, url, setLesson } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formik = useFormik({
    initialValues: {
      title: "",
      file: "",
      language: "",
    },
    onSubmit: (data, { setErrors }) => {
      const formData = new FormData();
      formData.append(`file`, data.file);
      formData.append(`title`, data.title);
      formData.append(`language`, data.language);
      return api
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setLesson(res.data.data);
          handleClose();
          Swal.fire("Success", "Added Successfully", "success");
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ title: ["Something went wrong"] });
          }
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    values,
    setValues,
  } = formik;

  const handelFileChange = (event) => {
    setValues({ ...values, file: event.target.files[0] });
  };

  return (
    <>
      <Button
        sx={{ mt: 1 }}
        variant="outlined"
        onClick={handleOpen}
        startIcon={<Icon icon="eva:plus-fill" width={24} height={24} />}
      >
        Add translation
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            sx={{ mb: 2 }}
            component="h2"
          >
            Add new file
          </Typography>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.language && errors.language)}
                >
                  <InputLabel id="demo-simple-select-label">
                    Language
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.language}
                    label="Language"
                    {...getFieldProps("language")}
                    helperText={touched.language && errors.language}
                  >
                    {languages.map((option, idx) => (
                      <MenuItem value={option.lang} key={idx}>
                        {option.language}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  autoComplete="text"
                  required
                  type="text"
                  label="Title"
                  {...getFieldProps("title")}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />

                <FormControl>
                  <FormLabel>File *</FormLabel>
                  <input
                    type="file"
                    fullWidth
                    name="file"
                    accept={fileType}
                    onChange={handelFileChange}
                  />
                </FormControl>
              </Stack>

              <LoadingButton
                fullWidth
                sx={{ marginTop: 2 }}
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Upload
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </>
  );
};

export default AddFileTranslation;
