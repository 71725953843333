const tabs = [
  {
    label: "Social Media",
    href: `/social_media`,
  },
  {
    label: "Site Policies",
    href: `/site_policies`,
  },
  {
    label: "FAQ",
    href: `/faq`,
  },
];

export default tabs;
