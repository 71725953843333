import { Card, CardContent, Container, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import apiRoutes from "../../routes/apiRoutes";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import { Link } from "react-router-dom";
import moment from "moment";
import api from "../../components/helpers/api";
import { swalError } from "../../components/helpers";

const ShowMessage = (props) => {
  const messageId = props.match.params.id;
  const [message, setMessage] = useState({});

  useEffect(() => {
    _getMessage();
    api
      .patch(apiRoutes.messages.markRead + "/" + messageId)
      .then(() => {})
      .catch(() => {
        swalError();
      });
    // eslint-disable-next-line
  }, []);

  const _getMessage = () => {
    api
      .get(apiRoutes.messages.show + "/" + messageId)
      .then((response) => {
        const data = response.data.data;
        setMessage(data);
      })
      .catch(() => {
        swalError();
      });
  };

  return (
    <Page title="Show Message">
      <PageHeader
        title="Show Message"
        homeLink={can(permissions.messages.view) && "/messages"}
        homeText="Messages"
      />
      <Container>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  From
                </Grid>
                <Grid item xs={10}>
                  {message.user && can(permissions.users.view) ? (
                    <Link to={`/users/show/${message.user?.id}`}>
                      {message.name}
                    </Link>
                  ) : (
                    message.name
                  )}
                  : ({message.email})
                </Grid>
                <Grid item xs={2}>
                  Message
                </Grid>
                <Grid item xs={10} style={{ whiteSpace: "pre-wrap" }}>
                  {message.message}
                </Grid>
                <Grid item xs={2}>
                  Created at
                </Grid>
                <Grid item xs={10}>
                  {moment(message.created_at).fromNow()}
                </Grid>
              </Grid>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default ShowMessage;
