import {
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import apiRoutes from "../../routes/apiRoutes";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import moment from "moment";
import api from "../../components/helpers/api";
import MultiLanguageTable from "../../components/MultiLanguageTable";
import {
  getFirstValue,
  roundCurrency,
  swalError,
} from "../../components/helpers";
import { Box } from "@mui/system";
import { Icon } from "@iconify/react";
import CreatedAt from "../../components/CreatedAt";
import { styled } from "@mui/styles";
import NavTabs from "../../components/NavTabs";

const ShowCourse = (props) => {
  const courseId = props.match.params.id;
  const [course, setCourse] = useState({});

  useEffect(() => {
    _getCourse();
    // eslint-disable-next-line
  }, []);

  const _getCourse = () => {
    api
      .get(apiRoutes.courses.show + "/" + courseId)
      .then((response) => {
        const data = response.data.data;
        setCourse(data);
      })
      .catch(() => {
        swalError();
      });
  };

  const CoverImgStyle = styled("img")({
    width: "100%",
    height: "100%",
    objectFit: "cover",
  });

  return (
    <Page title="Show Course">
      <PageHeader
        title="Show Course"
        homeLink={
          can(permissions.courses.view) && `/courses/${course.category_id}`
        }
        homeText="Courses"
        createLink={
          can(permissions.courses.create) &&
          `/courses/create/${course.category_id}`
        }
        createText="Create"
        editLink={
          can(permissions.courses.update) && `/courses/edit/${course.id}`
        }
        editText="Edit"
      />
      <Container>
        <NavTabs
          tabs={[
            {
              label: "Course info",
              href: `/courses/show/${course.id}`,
            },
            {
              label: "Lessons",
              href: `/lessons/${course.id}`,
            },
          ]}
        />
        <Card>
          <CardContent>
            <Stack spacing={3}>
              {course.promo_video && (
                <video controls>
                  <source
                    src={course.promo_video}
                    srcSet={course.promo_video}
                  />
                </video>
              )}
              <CoverImgStyle
                src={`${course.image?.image_url}`}
                srcSet={`${course.image?.image_url}`}
                alt={getFirstValue(course.title)}
                loading="lazy"
              />
              <MultiLanguageTable title="Title" content={course.title} />
              <MultiLanguageTable
                title="Description"
                content={course.description}
              />
              <MultiLanguageTable
                title="Full Description"
                content={course.full_description}
                enableHTML={true}
              />
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  Published
                </Grid>
                <Grid item xs={10}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: course.published_at ? "green" : "grey.500",
                    }}
                  >
                    <Box
                      component={Icon}
                      icon={"eva:checkmark-circle-2-fill"}
                      sx={{ width: 16, height: 16, mr: 0.5 }}
                    />
                    <Typography variant="caption">
                      {course.published_at
                        ? `Published at ${CreatedAt(course.published_at)}`
                        : "Not published"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  Price
                </Grid>
                <Grid item xs={10}>
                  {course.price ? `${roundCurrency(course.price)}$` : "Free"}
                </Grid>
                <Grid item xs={2}>
                  Created at
                </Grid>
                <Grid item xs={10}>
                  {moment(course.created_at).fromNow()}
                </Grid>
              </Grid>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default ShowCourse;
