import React from "react";
import Error from "./Error";
import cookie from "js-cookie";
import Swal from "sweetalert2";

export const renderErrors = (errors) => {
  if (!(Object.entries(errors).length === 0 && errors.constructor === Object)) {
    return Object.keys(errors).map((val, key) => {
      const error = errors[val];
      if (Array.isArray(error)) {
        return <Error key={key} error={error[0]} />;
      }
      return <Error key={key} error={error} />;
    });
  }
};

export const storeLoginToken = (token, remember = false) => {
  !remember
    ? cookie.set("token", token)
    : cookie.set("token", token, { expires: 365 });
};

export const isEmptyObj = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const compare = (a, b) => {
  if (a.cat < b.cat) {
    return -1;
  }
  if (a.cat > b.cat) {
    return 1;
  }
  return 0;
};

export const emptyPromise = () => {
  return new Promise((resolve, reject) => {
    resolve(null);
  });
};

export const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export const search = (arr, obj) => {
  return arr.indexOf(obj) !== -1;
};

export const createFormData = (images, body) => {
  const data = new FormData();

  Object.keys(images).forEach((key) => {
    for (let index = 0; index < images[key].length; index++) {
      const image = images[key][index];
      if (image === "" || image === null) {
        data.append(key, null);
      } else {
        data.append(key, image);
      }
    }
  });

  Object.keys(body).forEach((key) => {
    if (body[key] === "" || body[key] === null) {
      data.append(key, "");
    } else if (typeof body[key] === "object") {
      data.append(key, JSON.stringify(body[key]));
    } else {
      data.append(key, body[key].toString());
    }
  });

  return data;
};

export const getFullHostname = () => {
  const url = window.location.href;
  const arr = url.split("/");
  return arr[0] + "//" + arr[2];
};

export const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const swalError = () => {
  return Swal.fire("Oops...", "Something went wrong!", "error");
};

export const roundCurrency = (value) => {
  return parseFloat(value).toFixed(2);
};

export const getFirstValue = (data) => {
  if (data) {
    return Object.keys(data).map((key) => data[key])[0];
  }
  return "";
};
