import { Card, CardContent, Container, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import apiRoutes from "../../routes/apiRoutes";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import { Link } from "react-router-dom";
import moment from "moment";
import api from "../../components/helpers/api";
import { swalError } from "../../components/helpers";

const ShowAdmin = (props) => {
  const adminId = props.match.params.id;
  const [admin, setAdmin] = useState({});

  useEffect(() => {
    _getAdmin();
    // eslint-disable-next-line
  }, []);

  const _getAdmin = () => {
    api
      .get(apiRoutes.admins.show + "/" + adminId)
      .then((response) => {
        const data = response.data.data;
        setAdmin(data);
      })
      .catch((error) => {
        swalError();
      });
  };

  return (
    <Page title="Show Admin">
      <PageHeader
        title="Show Admin"
        homeLink={can(permissions.admins.view) && "/admins"}
        homeText="Admins"
        createLink={can(permissions.admins.create) && "/admins/create"}
        createText="Create"
        editLink={can(permissions.admins.update) && `/admins/edit/${admin.id}`}
        editText="Edit"
      />
      <Container>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <h1>{admin.name}</h1>
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  Email Address
                </Grid>
                <Grid item xs={10}>
                  {admin.email}
                </Grid>
                <Grid item xs={2}>
                  Role
                </Grid>
                <Grid item xs={10}>
                  <Link to={`/roles/show/${admin.role?.id}`}>
                    {admin.role?.name}
                  </Link>
                </Grid>
                <Grid item xs={2}>
                  Created at
                </Grid>
                <Grid item xs={10}>
                  {moment(admin.created_at).fromNow()}
                </Grid>
              </Grid>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default ShowAdmin;
