import apiRoutes from "../../routes/apiRoutes";
import store from "../../store";
import api from "../helpers/api";

export const getSidebarBadge = () => {
  api
    .get(apiRoutes.dashboard.sidebar)
    .then((response) => {
      store.dispatch({ type: "SET_SIDEBAR_DATA", payload: response.data });
    })
    .catch(() => {});
};
