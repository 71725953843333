import {
  Card,
  CardContent,
  Container,
  FormControl,
  Stack,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import * as Yup from "yup";
import apiRoutes from "../../routes/apiRoutes";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import { compare, groupBy, search, swalError } from "../../components/helpers";
import api from "../../components/helpers/api";

const CreateRole = (props) => {
  const history = useHistory();
  const [allPermissions, setAllPermissions] = useState();
  const [isAllSelected, setIsAllSelected] = useState(false);

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  useEffect(() => {
    _getPermissions();
  }, []);

  const _getPermissions = () => {
    api
      .get(apiRoutes.roles.permissions)
      .then((response) => {
        let permissions = response.data;
        permissions = permissions.map(function (permission) {
          var permissionTemp = Object.assign({}, permission);
          permissionTemp.cat = permission.name.split(" ")[1];
          return permissionTemp;
        });
        permissions.sort(compare);
        const groupByCat = groupBy("cat");
        permissions = groupByCat(permissions);
        console.log(permissions);
        setAllPermissions(permissions);
      })
      .catch((error) => {
        swalError();
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      permissions: [],
    },
    validationSchema: LoginSchema,
    onSubmit: (data, { setErrors }) => {
      return api
        .post(`${apiRoutes.roles.create}`, data)
        .then((res) => {
          Swal.fire("Success", "Created Successfully", "success");
          history.push("/roles");
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ name: ["Something went wrong"] });
          }
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    values,
    setValues,
  } = formik;

  const handleCheckbox = (key) => {
    const { permissions } = values;
    if (search(permissions, key)) {
      setValues({
        ...values,
        permissions: permissions.filter((item, index) => item !== key),
      });
    } else {
      setValues({
        ...values,
        permissions: [...permissions, key],
      });
    }
  };

  const handleAllCheckbox = () => {
    if (!isAllSelected) {
      const ids = Object.keys(allPermissions).map((key) =>
        allPermissions[key].map((permission) => permission.id)
      );
      var all = [];
      ids.forEach((id) => all.push(...id));
      setValues({ ...values, permissions: all });
      setIsAllSelected(true);
    } else {
      setValues({ ...values, permissions: [] });
      setIsAllSelected(false);
    }
  };

  return (
    <Page title="Create Role">
      <PageHeader
        title="Create Role"
        homeLink={can(permissions.roles.view) && "/roles"}
        homeText="Roles"
      />
      <Container>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="name"
                    type="text"
                    label="Name"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />

                  <FormControl>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="h5">Permissions</Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isAllSelected}
                            onChange={handleAllCheckbox}
                            color="primary"
                          />
                        }
                        label="Select all"
                      />
                    </Stack>
                    <Grid container spacing={2}>
                      {allPermissions &&
                        Object.keys(permissions).map((key) => {
                          return (
                            <Fragment key={key}>
                              <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                  {key.charAt(0).toUpperCase() + key.slice(1)}
                                </Typography>
                              </Grid>
                              {allPermissions[key].map((permission) => {
                                return (
                                  <Grid key={permission.id} item xs={3}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={search(
                                            values.permissions,
                                            permission.id
                                          )}
                                          onChange={() =>
                                            handleCheckbox(permission.id)
                                          }
                                          value={permission.id}
                                          color="primary"
                                        />
                                      }
                                      label={permission.name}
                                    />
                                  </Grid>
                                );
                              })}
                            </Fragment>
                          );
                        })}
                    </Grid>
                  </FormControl>
                </Stack>

                <LoadingButton
                  fullWidth
                  sx={{ marginTop: 2 }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default CreateRole;
