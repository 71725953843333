import {
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import apiRoutes from "../../routes/apiRoutes";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import moment from "moment";
import api from "../../components/helpers/api";
import MultiLanguageTable from "../../components/MultiLanguageTable";
import { swalError } from "../../components/helpers";
import { Box } from "@mui/system";
import { Icon } from "@iconify/react";
import checkmarkFill from "@iconify/icons-eva/checkmark-circle-2-fill";
import CreatedAt from "../../components/CreatedAt";
import FilesCard from "../../components/_dashboard/lessons/FilesCard";
import VideosCard from "../../components/_dashboard/lessons/VideosCard";
import AudiosCard from "../../components/_dashboard/lessons/AudiosCard";

const ShowLesson = (props) => {
  const lessonId = props.match.params.id;
  const [lesson, setLesson] = useState({});

  useEffect(() => {
    _getLesson();
    // eslint-disable-next-line
  }, []);

  const _getLesson = () => {
    api
      .get(apiRoutes.lessons.show + "/" + lessonId)
      .then((response) => {
        const data = response.data.data;
        setLesson(data);
      })
      .catch(() => {
        swalError();
      });
  };

  const removeFile = (fileId) => {
    const files = lesson.files.filter((file) => {
      return file.id !== fileId;
    });
    setLesson({ ...lesson, files: files });
  };

  const removeVideo = (videoId) => {
    const videos = lesson.videos.filter((video) => {
      return video.id !== videoId;
    });
    setLesson({ ...lesson, videos: videos });
  };

  const removeAudio = (audioId) => {
    const audios = lesson.audios.filter((audio) => {
      return audio.id !== audioId;
    });
    setLesson({ ...lesson, audios: audios });
  };

  return (
    <Page title="Show Lesson">
      <PageHeader
        title="Show Lesson"
        homeLink={
          can(permissions.lessons.view) && `/lessons/${lesson.course_id}`
        }
        homeText="lessons"
        createLink={
          can(permissions.lessons.create) &&
          `/lessons/create/${lesson.course_id}`
        }
        createText="Create"
        editLink={
          can(permissions.lessons.update) && `/lessons/edit/${lesson.id}`
        }
        editText="Edit"
      />
      <Container>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <MultiLanguageTable title="Title" content={lesson.title} />
              <FilesCard
                lessonId={lessonId}
                setLesson={setLesson}
                files={lesson.files}
                removeFile={removeFile}
              />
              <VideosCard
                lessonId={lessonId}
                setLesson={setLesson}
                videos={lesson.videos}
                removeVideo={removeVideo}
              />
              <AudiosCard
                lessonId={lessonId}
                setLesson={setLesson}
                audios={lesson.audios}
                removeAudio={removeAudio}
              />
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  Published
                </Grid>
                <Grid item xs={10}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: lesson.published_at ? "green" : "grey.500",
                    }}
                  >
                    <Box
                      component={Icon}
                      icon={checkmarkFill}
                      sx={{ width: 16, height: 16, mr: 0.5 }}
                    />
                    <Typography variant="caption">
                      {lesson.published_at
                        ? `Published at ${CreatedAt(lesson.published_at)}`
                        : "Not published"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  Paid
                </Grid>
                <Grid item xs={10}>
                  {lesson.paid ? `Paid` : "Free"}
                </Grid>
                <Grid item xs={2}>
                  Created at
                </Grid>
                <Grid item xs={10}>
                  {moment(lesson.created_at).fromNow()}
                </Grid>
              </Grid>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default ShowLesson;
