import {
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  Stack,
  TextField,
  Box,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import * as Yup from "yup";
import apiRoutes from "../../routes/apiRoutes";
import { DatePicker, LoadingButton, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import countries from "../../components/data/countries";
import api from "../../components/helpers/api";

const CreateUser = (props) => {
  const history = useHistory();

  const LoginSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    birth_date: Yup.string().required("Birth date is required"),
    lang: Yup.string().required("Language is required"),
    country: Yup.string().required("Country is required"),
    mobile_number: Yup.string(),
    gender: Yup.string().required("Gender is required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      birth_date: "",
      lang: "",
      mobile_number: "",
      country: "",
      gender: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (data, { setErrors }) => {
      return api
        .post(`${apiRoutes.users.create}`, data)
        .then((res) => {
          Swal.fire("Success", "Created Successfully", "success");
          history.push("/users");
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ first_name: ["Something went wrong"] });
          }
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    setValues,
    handleSubmit,
    getFieldProps,
    values,
  } = formik;

  return (
    <Page title="Create User">
      <PageHeader
        title="Create User"
        homeLink={can(permissions.users.view) && "/users"}
        homeText="Users"
      />
      <Container>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="name"
                    type="text"
                    label="First name"
                    {...getFieldProps("first_name")}
                    error={Boolean(touched.first_name && errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                  />
                  <TextField
                    fullWidth
                    autoComplete="name"
                    type="text"
                    label="Last name"
                    {...getFieldProps("last_name")}
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                  <TextField
                    fullWidth
                    autoComplete="username"
                    type="email"
                    label="Email address"
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    fullWidth
                    autoComplete="language"
                    type="text"
                    label="Language"
                    {...getFieldProps("lang")}
                    error={Boolean(touched.lang && errors.lang)}
                    helperText={touched.lang && errors.lang}
                  />

                  <FormControl
                    error={Boolean(touched.country && errors.country)}
                  >
                    <Autocomplete
                      id="country-select-demo"
                      options={countries}
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      onInputChange={(event, newInputValue) => {
                        setValues({ ...values, country: newInputValue });
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.label} ({option.code})
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    <FormHelperText>
                      {touched.country && errors.country}
                    </FormHelperText>
                  </FormControl>

                  
                  <FormControl error={Boolean(touched.gender && errors.gender)}>
                    <InputLabel>Gender</InputLabel>
                    <Select {...getFieldProps("gender")}>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                    </Select>
                    <FormHelperText>
                      {touched.gender && errors.gender}
                    </FormHelperText>
                  </FormControl>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormControl
                      error={touched.birth_date && errors.birth_date}
                    >
                      <DatePicker
                        {...getFieldProps("birth_date")}
                        value={values.birth_date}
                        onChange={(newValue) => {
                          setValues({ ...values, birth_date: newValue });
                        }}
                        disableFuture
                        fullWidth
                        label="Birth date"
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <FormHelperText>
                        {touched.birth_date && errors.birth_date}
                      </FormHelperText>
                    </FormControl>
                  </LocalizationProvider>

                  <TextField
                    fullWidth
                    autoComplete="language"
                    type="text"
                    label="Mobile number"
                    {...getFieldProps("mobile_number")}
                    error={Boolean(
                      touched.mobile_number && errors.mobile_number
                    )}
                    helperText={touched.mobile_number && errors.mobile_number}
                  />
                </Stack>

                <LoadingButton
                  fullWidth
                  sx={{ marginTop: 2 }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default CreateUser;
