import {
  Card,
  CardContent,
  Container,
  FormControl,
  Stack,
  FormLabel,
  FormHelperText,
  TextField,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import * as Yup from "yup";
import apiRoutes from "../../routes/apiRoutes";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import api from "../../components/helpers/api";
import MultiInput from "../../components/forms/MultiInput";
import { createFormData, swalError } from "../../components/helpers";
import MultiEditor from "../../components/forms/MultiEditor";

const EditCourse = (props) => {
  const history = useHistory();
  const courseId = props.match.params.id;
  const [course, setCourse] = useState({});

  const LoginSchema = Yup.object().shape({
    // name: Yup.string().required("Name is required"),
  });

  useEffect(() => {
    _getCourse();
    // eslint-disable-next-line
  }, []);

  const _getCourse = () => {
    api
      .get(apiRoutes.courses.show + "/" + courseId)
      .then((response) => {
        const data = response.data.data;
        setCourse(data);
      })
      .catch(() => {
        swalError();
      });
  };

  const formik = useFormik({
    initialValues: {
      title: course.title,
      description: course.description,
      full_description: course.full_description ?? {},
      price: course.price,
      image: "",
      video: "",
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (data, { setErrors }) => {
      return api
        .post(
          `${apiRoutes.courses.update}/${course.id}`,
          createFormData(
            {
              image: [data.image],
              video: [data.video],
            },
            {
              title: data.title,
              description: data.description,
              full_description: data.full_description,
              price: data.price,
              _method: "PATCH",
            }
          )
        )
        .then((res) => {
          Swal.fire("Success", "Updated Successfully", "success");
          history.push(`/courses/${course.category_id}`);
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ name: ["Something went wrong"] });
          }
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    values,
    setValues,
  } = formik;

  const handleSetValue = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  return (
    <Page title="Edit Course">
      <PageHeader
        title="Edit Course"
        homeLink={
          can(permissions.courses.view) && `/courses/${course.category_id}`
        }
        homeText="Courses"
        createLink={
          can(permissions.courses.create) &&
          `/courses/create/${course.category_id}`
        }
        createText="Create"
        showLink={can(permissions.courses.view) && `/courses/show/${course.id}`}
        showText="Show"
      />
      <Container>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <MultiInput
                    fullWidth
                    type="text"
                    label="Title"
                    {...getFieldProps("title")}
                    required
                    handleSetValue={handleSetValue}
                    error={Boolean(
                      (touched.title && errors.title) ||
                        Object.keys(errors).filter((error) =>
                          /title/.test(error)
                        ).length
                    )}
                    helperText={touched.title && errors.title}
                  />

                  <MultiInput
                    fullWidth
                    type="text"
                    label="Description"
                    {...getFieldProps("description")}
                    multiline
                    rows={4}
                    handleSetValue={handleSetValue}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />

                  <FormLabel>Full description</FormLabel>
                  <MultiEditor
                    fullWidth
                    type="text"
                    label="Full description"
                    {...getFieldProps("full_description")}
                    required
                    handleSetValue={handleSetValue}
                    error={Boolean(
                      (touched.full_description && errors.full_description) ||
                        Object.keys(errors).filter((error) =>
                          /full_description/.test(error)
                        ).length
                    )}
                    helperText={
                      touched.full_description && errors.full_description
                    }
                  />

                  <TextField
                    fullWidth
                    autoComplete="price"
                    type="number"
                    label="Price"
                    required
                    min="0"
                    {...getFieldProps("price")}
                    error={Boolean(touched.price && errors.price)}
                    helperText={touched.price && errors.price}
                  />

                  <FormControl error={Boolean(touched.image && errors.image)}>
                    <FormLabel>Image</FormLabel>
                    <input
                      type="file"
                      fullWidth
                      label="Image"
                      accept="image/*"
                      onChange={(event) =>
                        setValues({ ...values, image: event.target.files[0] })
                      }
                    />
                    <FormHelperText>
                      {touched.image && errors.image}
                    </FormHelperText>
                  </FormControl>

                  <FormControl error={Boolean(touched.video && errors.video)}>
                    <FormLabel>Promo Video</FormLabel>
                    <input
                      type="file"
                      fullWidth
                      label="Promo Video"
                      accept="video/*"
                      onChange={(event) =>
                        setValues({ ...values, video: event.target.files[0] })
                      }
                    />
                    <FormHelperText>
                      {touched.video && errors.video}
                    </FormHelperText>
                  </FormControl>
                </Stack>

                <LoadingButton
                  fullWidth
                  sx={{ marginTop: 2 }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default EditCourse;
