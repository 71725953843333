import {
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import * as Yup from "yup";
import apiRoutes from "../../routes/apiRoutes";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import api from "../../components/helpers/api";
import { swalError } from "../../components/helpers";

const EditAdmin = (props) => {
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const adminId = props.match.params.id;
  const [admin, setAdmin] = useState({});

  const LoginSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    role: Yup.string().required("Role is required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  useEffect(() => {
    _getRoles();
    _getAdmin();
    // eslint-disable-next-line
  }, []);

  const _getAdmin = () => {
    api
      .get(apiRoutes.admins.show + "/" + adminId)
      .then((response) => {
        const data = response.data.data;
        setAdmin(data);
      })
      .catch((error) => {
        swalError();
      });
  };

  const _getRoles = () => {
    api
      .get(apiRoutes.admins.roles)
      .then((response) => {
        const { data } = response.data;
        setRoles(data);
      })
      .catch((error) => {
        swalError();
      });
  };

  const formik = useFormik({
    initialValues: {
      first_name: admin.first_name,
      last_name: admin.last_name,
      email: admin.email,
      role: admin.role?.id ?? "",
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (data, { setErrors }) => {
      return api
        .patch(`${apiRoutes.admins.update}/${admin.id}`, data)
        .then((res) => {
          Swal.fire("Success", "Updated Successfully", "success");
          history.push("/admins");
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ first_name: ["Something went wrong"] });
          }
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Edit Admin">
      <PageHeader
        title="Edit Admin"
        homeLink={can(permissions.admins.view) && "/admins"}
        homeText="Admins"
        createLink={can(permissions.admins.create) && "/admins/create"}
        createText="Create"
        showLink={can(permissions.admins.view) && `/admins/show/${admin.id}`}
        showText="Show"
      />
      <Container>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="name"
                    type="text"
                    label="First name"
                    {...getFieldProps("first_name")}
                    error={Boolean(touched.first_name && errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                  />
                  <TextField
                    fullWidth
                    autoComplete="name"
                    type="text"
                    label="Last name"
                    {...getFieldProps("last_name")}
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                  <TextField
                    fullWidth
                    autoComplete="username"
                    type="email"
                    label="Email address"
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <FormControl error={Boolean(touched.role && errors.role)}>
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select {...getFieldProps("role")}>
                      {roles.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.role && errors.role}
                    </FormHelperText>
                  </FormControl>
                </Stack>

                <LoadingButton
                  fullWidth
                  sx={{ marginTop: 2 }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default EditAdmin;
