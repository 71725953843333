import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router";
import { useFormik, Form, FormikProvider } from "formik";
import { Link, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import apiRoutes from "../../../routes/apiRoutes";
import api from "../../helpers/api";

const ForgetPasswordForm = () => {
  const history = useHistory();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (data, { setErrors }) => {
      return api
        .post(`${apiRoutes.auth.password.forget}`, data)
        .then(() => {
          history.push(`/check/password?email=${data.email}`);
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ email: ["Something went wrong"] });
          }
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <Link component={RouterLink} variant="subtitle2" to="/login">
            Back to Login
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Reset Password
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
};

export default ForgetPasswordForm;
