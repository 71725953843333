import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import apiRoutes from "../../routes/apiRoutes";
import MultiEditor from "../forms/MultiEditor";
import api from "../helpers/api";

const AboutUs = (props) => {
  const LoginSchema = Yup.object().shape({});

  const formik = useFormik({
    initialValues: {
      value: props.value?.value ?? "",
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (data, { setErrors }) => {
      return api
        .post(`${apiRoutes.sitePolicies.aboutUs}`, data)
        .then(() => {
          Swal.fire("Success", "Updated Successfully", "success");
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ value: ["Something went wrong"] });
          }
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    values,
    setValues,
  } = formik;

  const handleSetValue = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  return (
    <div>
      <Card>
        <CardContent>
          <Typography component="h4" variant="h4" sx={{ mb: 3 }}>
            About Us
          </Typography>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <MultiEditor
                  fullWidth
                  type="text"
                  label="About Us"
                  {...getFieldProps("value")}
                  required
                  handleSetValue={handleSetValue}
                  error={Boolean(
                    (touched.value && errors.value) ||
                      Object.keys(errors).filter((error) => /value/.test(error))
                        .length
                  )}
                  helperText={touched.value && errors.value}
                />
              </Stack>

              <LoadingButton
                fullWidth
                sx={{ marginTop: 2 }}
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Save
              </LoadingButton>
            </Form>
          </FormikProvider>
        </CardContent>
      </Card>
    </div>
  );
};

export default AboutUs;
