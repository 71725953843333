const languages = [
  { code: "EG", label: "Egypt", phone: "20", language: "العربية", lang: "ar" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    language: "English",
    lang: "en",
  },
  {
    code: "RU",
    label: "Russian Federation",
    phone: "7",
    language: "русский",
    lang: "ru",
  },
  {
    code: "UZ",
    label: "Uzbekistan",
    phone: "998",
    language: "O'zbek",
    lang: "uz",
  },
  {
    code: "KG",
    label: "Kyrgyzstan",
    phone: "996",
    language: "Кыргызча",
    lang: "ky",
  },
  {
    code: "FR",
    label: "France",
    phone: "33",
    language: "français",
    lang: "fr",
  },
  {
    code: "NL",
    label: "Netherlands",
    phone: "31",
    language: "Nederlands",
    lang: "nl",
  },
  {
    code: "BG",
    label: "Bulgaria",
    phone: "359",
    language: "български",
    lang: "bg",
  },
];

export default languages;
