import { createStore } from "redux";
import AllReducers from "./reducers";

const initialStates = {
  auth: {
    loggedIn: false,
    user: {},
    permissions: [],
  },
  sidebar: {
    data: {
      comments: 0,
      messages: 0,
    },
  },
  roles: {
    data: [],
  },
  admins: {
    data: [],
  },
  users: {
    data: [],
  },
  categories: {
    data: [],
  },
  courses: {
    data: [],
  },
  lessons: {
    data: [],
  },
  lesson_comments: {
    data: [],
  },
  blog: {
    data: [],
  },
  blog_comments: {
    data: [],
  },
  enrollments: {
    data: [],
  },
  messages: {
    data: [],
  },
  visitors: {
    data: [],
  },
  settings: {
    socialMedia: [],
    sitePolicies: [],
    faq: [],
  },
};

const store = createStore(
  AllReducers,
  initialStates,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
