import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import languages from "../../forms/languages";
import { Button, Card } from "@mui/material";
import Activate from "../../Activate";
import { Icon } from "@iconify/react";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import api from "../../helpers/api";
import Swal from "sweetalert2";
import { swalError } from "../../helpers";
import AddFileTranslation from "./AddFileTranslation";
import { Draggable } from "react-beautiful-dnd";

const FilesTable = (props) => {
  const {
    id,
    index,
    title,
    paths,
    activateUrl,
    activeValue,
    addTranslationUrl,
    fileType,
    setLesson,
  } = props;

  const handleDeleteAll = () => {
    if (window.confirm("Are you sure?")) {
      const { deleteLink, noDeleteMethod } = props;
      if (noDeleteMethod) {
        api
          .post(deleteLink)
          .then(() => {
            Swal.fire("Success", "Deleted Successfully", "success");
            props.removeItem();
          })
          .catch(() => {
            swalError();
          });
      } else {
        api
          .delete(deleteLink)
          .then(() => {
            Swal.fire("Success", "Deleted Successfully", "success");
            props.removeItem();
          })
          .catch(() => {
            swalError();
          });
      }
    }
  };

  const handleDelete = (lang) => {
    if (window.confirm("Are you sure?")) {
      const { deleteOneLink } = props;
      api
        .post(deleteOneLink, {
          language: lang,
          _method: "DELETE",
        })
        .then((res) => {
          Swal.fire("Success", "Deleted Successfully", "success");
          setLesson(res.data.data);
        })
        .catch(() => {
          swalError();
        });
    }
  };

  return (
    <Draggable draggableId={`${id.toString()}`} index={index}>
      {(provided) => (
        <Card sx={{ marginBottom: 2 }}>
          <TableContainer
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            component={Paper}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "150px" }}>Languages</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>File</TableCell>
                  <TableCell>
                    Activate:{" "}
                    <Activate url={activateUrl} checked={activeValue} />
                  </TableCell>
                  <TableCell>
                    <AddFileTranslation
                      url={addTranslationUrl}
                      fileType={fileType}
                      setLesson={setLesson}
                    />
                    <Button
                      sx={{ mx: 1, mt: 1 }}
                      variant="outlined"
                      color="error"
                      onClick={handleDeleteAll}
                      startIcon={
                        <Icon icon={trash2Outline} width={24} height={24} />
                      }
                    >
                      Delete All
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(title).map((key, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {languages.find((lang) => lang.lang === key).language}
                    </TableCell>
                    <TableCell>{title[key]}</TableCell>
                    <TableCell>
                      <Button target="_blank" href={paths[key]}>
                        Go to {title[key]} File
                      </Button>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <Button
                        sx={{ mx: 1 }}
                        variant="outlined"
                        color="error"
                        onClick={() => handleDelete(key)}
                        startIcon={
                          <Icon icon={trash2Outline} width={24} height={24} />
                        }
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}
    </Draggable>
  );
};

export default FilesTable;
