import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LinearProgress, Container, Card, CardContent } from "@mui/material";
import DataTable from "react-data-table-component";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import Page from "../../components/Page";
import apiRoutes from "../../routes/apiRoutes";
import store from "../../store";
import CreatedAt from "../../components/CreatedAt";
import { SearchToolBar, MoreMenu } from "../../components/_dashboard/user";
import PageHeader from "../../components/PageHeader";
import api from "../../components/helpers/api";
import { swalError } from "../../components/helpers";

const columns = [
  {
    name: "#",
    selector: "id",
    sortable: true,
    maxWidth: "60px",
  },
  {
    name: "Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "Created At",
    selector: "created_at",
    cell: (row) => CreatedAt(row),
  },
  {
    name: "Controls",
    selector: "actions",
    maxWidth: "60px",
    cell: (row) => (
      <MoreMenu
        editLink={
          row.can_update &&
          can(permissions.roles.update) &&
          `/roles/edit/${row.id}`
        }
        showLink={can(permissions.roles.view) && `/roles/show/${row.id}`}
        removeItem={() => removeItem(row.id)}
        deleteLink={
          row.can_delete &&
          can(permissions.roles.delete) &&
          apiRoutes.roles.delete + "/" + row.id
        }
      />
    ),
  },
];

const removeItem = (id) => {
  const data = store.getState().roles.data.filter((admin) => {
    return admin.id !== id;
  });
  store.dispatch({ type: "SET_ROLES_DATA", payload: data });
};

const Roles = (props) => {
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <SearchToolBar
        filterText={filterText}
        onFilterText={(e) => setFilterText(e.target.value)}
      />
    );
  }, [filterText]);

  useEffect(() => {
    fetchData(1, perPage);
    // eslint-disable-next-line
  }, [perPage, filterText]);

  const fetchData = async (page, perPage) => {
    setLoading(true);

    api
      .get(
        apiRoutes.roles.all +
          `?page=${page}&per_page=${perPage}&search=${filterText}`
      )
      .then((response) => {
        props.setRolesData(response.data.data);
        setTotalRows(response.data.meta.total);
        setPerPage(perPage);
      })
      .catch((error) => {
        swalError();
      });

    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchData(page, newPerPage);
  };

  return (
    <Page title="Roles">
      <PageHeader
        title="Roles"
        createLink={can(permissions.roles.create) && "/roles/create"}
        createText="Create Role"
      />
      <Container>
        <Card>
          <CardContent>
            <DataTable
              title="Roles Table"
              columns={columns}
              data={props.roles}
              progressPending={loading}
              pagination
              paginationServer
              highlightOnHover
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              progressComponent={<LinearProgress />}
              persistTableHead
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    roles: state.roles.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRolesData: (data) =>
      dispatch({
        type: "SET_ROLES_DATA",
        payload: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
