import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Drawer } from "@mui/material";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
//
// import sidebarConfig from "./SidebarConfig";
import { Icon } from "@iconify/react";
import permissions from "../../components/permissions/permissions";
import { connect } from "react-redux";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

const DashboardSidebar = ({
  isOpenSidebar,
  onCloseSidebar,
  comments,
  messages,
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

  const sidebarConfig = [
    {
      title: "dashboard",
      path: "/home",
      icon: getIcon("eva:pie-chart-2-fill"),
    },
    {
      title: "admins",
      path: "/admins",
      icon: getIcon("eva:people-fill"),
      permission: permissions.admins.view,
    },
    {
      title: "roles",
      path: "/roles",
      icon: getIcon("eva:briefcase-fill"),
      permission: permissions.roles.view,
    },
    {
      title: "users",
      path: "/users",
      icon: getIcon("eva:person-fill"),
      permission: permissions.users.view,
    },
    {
      title: "Enrollments",
      path: "/enrollments",
      icon: getIcon("eva:done-all-fill"),
      permission: permissions.enrollments.view,
    },
    {
      title: "Visitors",
      path: "/visitors",
      icon: getIcon("eva:person-add-fill"),
      permission: permissions.visitors.view,
    },
    {
      title: "categories",
      path: "/categories",
      icon: getIcon("eva:file-text-fill"),
      permission: permissions.categories.view,
    },
    {
      title: "comments",
      path: "/comments",
      icon: getIcon("eva:browser-fill"),
      permission: permissions.lesson_comments.view,
      badge: comments,
    },
    {
      title: "blog",
      path: "/blog",
      icon: getIcon("grommet-icons:article"),
      permission: permissions.blog.view,
    },
    {
      title: "messages",
      path: "/messages",
      icon: getIcon("eva:phone-fill"),
      permission: permissions.messages.view,
      badge: messages,
    },
    {
      title: "settings",
      path: "/social_media",
      icon: getIcon("eva:settings-2-fill"),
      permission: permissions.messages.view,
    },
  ];

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
          <Logo />
        </Box>
      </Box>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
};

const mapStateToProps = (state) => {
  return {
    comments: state.sidebar.data.comments,
    messages: state.sidebar.data.messages,
  };
};

export default connect(mapStateToProps)(DashboardSidebar);
