// scroll bar
import "simplebar/src/simplebar.css";

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider as StoreProvider } from "react-redux";
// import axios from "axios";
import cookie from "js-cookie";
//
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import apiRoutes from "./routes/apiRoutes";
import { getPermissions } from "./components/permissions";
import api from "./components/helpers/api";
import { getSidebarBadge } from "./components/helpers/sidebar";

// ----------------------------------------------------------------------

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;

let token = cookie.get("token");

const logout = () => {
  cookie.remove("token");
  token = null;
};

const render = () => {
  ReactDOM.render(
    <StoreProvider store={store}>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </StoreProvider>,
    document.getElementById("root")
  );
};

if (token) {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  api
    .get(`${apiRoutes.auth.me}`)
    .then(async (res) => {
      store.dispatch({ type: "SET_LOGIN", payload: res.data });
      getSidebarBadge();
      getPermissions(render);
    })
    .catch((err) => {
      const { response } = err;
      if (
        response &&
        response.data.message &&
        response.data.message === "Unauthenticated."
      )
        logout();
      render();
    });
} else {
  render();
}

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
