import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Forbidden from "../pages/errors/Page403";
import { can } from "../components/helpers/Authorization";
import DashboardLayout from "../layouts/dashboard";
import { LinearProgress } from "@mui/material";

const AuthRoute = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);
  if (loading) {
    return <LinearProgress />;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.loggedIn ? (
          !rest.permission || can(rest.permission) ? (
            <DashboardLayout>
              <Component {...props} />
            </DashboardLayout>
          ) : (
            <Forbidden />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};
export default connect(mapStateToProps)(AuthRoute);
