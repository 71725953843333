import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { matchPath, useHistory, useLocation } from "react-router";

const LinkTab = (props) => {
  const history = useHistory();
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
        history.push(props.href);
      }}
      {...props}
    />
  );
};

const NavTabs = (props) => {
  const { pathname } = useLocation();
  const { tabs } = props;
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    const index = tabs.findIndex(
      (tab) => !!matchPath(pathname, { path: tab.href, exact: true })
    );
    setValue(index >= 0 ? index : 0);
  }, [tabs, pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={value} onChange={handleChange} aria-label="nav tabs example">
        {tabs.map((tab, index) => (
          <LinkTab key={index} label={tab.label} href={tab.href} />
        ))}
      </Tabs>
    </Box>
  );
};

export default NavTabs;
