import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";

const GuestRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !rest.loggedIn ? (
          <LogoOnlyLayout>
            <Component {...props} />
          </LogoOnlyLayout>
        ) : (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};
export default connect(mapStateToProps)(GuestRoute);
