import { Icon } from "@iconify/react";
// import eyeFill from "@iconify/icons-eva/eye-fill";
import { Link as RouterLink } from "react-router-dom";
// import messageCircleFill from "@iconify/icons-eva/message-circle-fill";
import checkmarkFill from "@iconify/icons-eva/checkmark-circle-2-fill";
import closeFill from "@iconify/icons-eva/close-circle-fill";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Card,
  Grid,
  Typography,
  CardContent,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// utils
import { fDate } from "../../../utils/formatTime";
//
import { MoreMenu } from "../user";
import { can } from "../../helpers/Authorization";
import permissions from "../../permissions/permissions";
import store from "../../../store";
import apiRoutes from "../../../routes/apiRoutes";
import api from "../../helpers/api";
import moment from "moment";
import { getFirstValue, roundCurrency, swalError } from "../../helpers";
import { Draggable } from "react-beautiful-dnd";
import Swal from "sweetalert2";
import Activate from "../../Activate";

// ----------------------------------------------------------------------

const CardMediaStyle = styled("div")({
  // position: "relative",
  // paddingTop: "calc(100% * 3 / 4)",
});

const TitleStyle = styled(Link)({
  // height: 44,
  overflow: "hidden",
  WebkitLineClamp: 2,
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
});

const InfoStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-end",
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled,
}));

const CoverImgStyle = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

// ----------------------------------------------------------------------

const removeItem = (id) => {
  const data = store.getState().courses.data.filter((course) => {
    return course.id !== id;
  });
  store.dispatch({ type: "SET_COURSES_DATA", payload: data });
};

const CourseCard = ({ course, index }) => {
  const {
    id,
    image,
    title,
    published_at,
    promo_video,
    description,
    price,
    created_at,
    home_view,
    downloadable,
  } = course;

  const handlePublish = () => {
    if (window.confirm("Are you sure?")) {
      api
        .patch(`${apiRoutes.courses.publish}/${id}`)
        .then((response) => {
          const courses = store.getState().courses.data.map((course) => {
            if (course.id === id) {
              return {
                ...course,
                published_at: course.published_at ? null : moment(),
              };
            }
            return course;
          });
          store.dispatch({ type: "SET_COURSES_DATA", payload: courses });
        })
        .catch(() => {
          swalError();
        });
    }
  };

  const removeVideo = (id) => {
    const data = store.getState().courses.data.map((course) => {
      return course.id === id ? { ...course, promo_video: null } : course;
    });
    store.dispatch({ type: "SET_COURSES_DATA", payload: data });
  };

  const handleDeleteVideo = () => {
    api
      .delete(`${apiRoutes.courses.deleteVideo}/${id}`, {})
      .then(() => {
        Swal.fire("Success", "Deleted Successfully", "success");
        removeVideo(id);
      })
      .catch(() => {
        swalError();
      });
  };

  return (
    <Draggable draggableId={`${id.toString()}`} index={index}>
      {(provided) => (
        <Grid
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          item
          xs={12}
        >
          <Card
            sx={{
              display: "flex",
              ...{ flexDirection: { xs: "column", sm: "row" } },
            }}
          >
            <CardMediaStyle>
              <CoverImgStyle
                sx={{
                  ...{
                    minWidth: {
                      sm: "250px",
                      md: "350px",
                    },
                    maxWidth: {
                      sm: "250px",
                      md: "350px",
                    },
                    maxHeight: {
                      sm: "250px",
                      md: "250px",
                    },
                  },
                }}
                alt={getFirstValue(title)}
                src={image.card_url}
              />
            </CardMediaStyle>

            <CardContent
              sx={{
                pt: 4,
                width: "100%",
              }}
            >
              <Grid container justifyContent="space-between">
                <Grid item xs wrap="wrap">
                  <Typography
                    gutterBottom
                    variant="caption"
                    sx={{ color: "text.disabled", display: "block" }}
                  >
                    {fDate(created_at)}
                  </Typography>

                  <TitleStyle
                    to={can(permissions.courses.view) && `/courses/show/${id}`}
                    color="inherit"
                    variant="subtitle2"
                    underline="hover"
                    component={RouterLink}
                  >
                    {getFirstValue(title)}
                  </TitleStyle>
                  <Typography
                    gutterBottom
                    variant="caption"
                    sx={{
                      display: "block",
                    }}
                  >
                    {getFirstValue(description)}
                  </Typography>
                </Grid>
                <Grid item wrap="nowrap">
                  <Typography color="inherit" variant="subtitle2">
                    {price ? `${roundCurrency(price)}$` : "Free"}
                  </Typography>
                  <MoreMenu
                    editLink={
                      can(permissions.courses.update) && `/courses/edit/${id}`
                    }
                    showLink={
                      can(permissions.courses.view) && `/courses/show/${id}`
                    }
                    removeItem={() => removeItem(id)}
                    deleteLink={
                      can(permissions.courses.delete) &&
                      apiRoutes.courses.delete + "/" + id
                    }
                  >
                    {can(permissions.courses.update) && (
                      <MenuItem
                        sx={{ color: "text.secondary" }}
                        onClick={handlePublish}
                      >
                        <ListItemIcon>
                          <Icon
                            icon={published_at ? closeFill : checkmarkFill}
                            width={24}
                            height={24}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={published_at ? "Unpublish" : "Publish"}
                          primaryTypographyProps={{ variant: "body2" }}
                        />
                      </MenuItem>
                    )}

                    {can(permissions.courses.delete) && promo_video && (
                      <MenuItem
                        sx={{ color: "text.secondary" }}
                        onClick={handleDeleteVideo}
                      >
                        <ListItemIcon>
                          <Icon
                            icon="bi:camera-video-fill"
                            width={24}
                            height={24}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Delete Video"
                          primaryTypographyProps={{ variant: "body2" }}
                        />
                      </MenuItem>
                    )}
                  </MoreMenu>
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="caption">Home view:</Typography>
                <Activate
                  url={apiRoutes.courses.homeToggle + "/" + id}
                  checked={home_view}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="caption">Download:</Typography>
                <Activate
                  url={apiRoutes.courses.downloadableToggle + "/" + id}
                  checked={downloadable}
                  activeTitle="Downloadable"
                  inactiveTitle="Not downloadable"
                />
              </Box>

              <InfoStyle>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    ml: index === 0 ? 0 : 1.5,
                    color: published_at ? "green" : "grey.500",
                  }}
                >
                  <Box
                    component={Icon}
                    icon={checkmarkFill}
                    sx={{ width: 16, height: 16, mr: 0.5 }}
                  />
                  <Typography variant="caption">
                    {published_at ? `Published` : "Not published"}
                  </Typography>
                </Box>
                {/* {POST_INFO.map((info, index) => (
                ))} */}
                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    ml: 1.5,
                    ...((latestPostLarge || latestPost) && {
                      color: "grey.500",
                    }),
                  }}
                >
                  <Box
                    component={Icon}
                    icon="eva:browser-fill"
                    sx={{ width: 16, height: 16, mr: 0.5 }}
                  />
                  <Typography variant="caption">
                    {fShortenNumber(comments_count)}
                  </Typography>
                </Box> */}
              </InfoStyle>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Draggable>
  );
};

export default CourseCard;
