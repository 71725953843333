import apiRoutes from "../../routes/apiRoutes";
import store from "../../store";
import api from "../helpers/api";

export const getPermissions = (render) => {
  api
    .get(apiRoutes.auth.permissions)
    .then((response) => {
      store.dispatch({ type: "SET_LOGIN_PERMISSIONS", payload: response.data });
      render();
    })
    .catch(() => {
      render();
    });
};
