import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import {
  Card,
  CardHeader,
  Box,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
//
import { BaseOptionChart } from "../../charts";
import { useEffect, useState } from "react";
import api from "../../helpers/api";
import apiRoutes from "../../../routes/apiRoutes";

// ----------------------------------------------------------------------

const AppWebsiteVisits = () => {
  const [selection, setSelection] = useState("weeks");
  const [visits, setVisits] = useState([]);

  const CHART_DATA = [
    {
      name: "Visits",
      type: "column",
      data: visits.map((visit) => visit.count),
    },
  ];

  useEffect(() => {
    api
      .get(`${apiRoutes.dashboard.visits}?period=${selection}`)
      .then((response) => setVisits(response.data.visits))
      .catch(() => {});
  }, [selection]);

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [4] },
    plotOptions: { bar: { columnWidth: "11%", borderRadius: 4 } },
    fill: { type: ["solid"] },
    labels: visits.map((visit) => visit.date),
    xaxis: { type: selection === "years" ? "string" : "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)} visits`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card>
      <CardHeader title="Website Visits" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ToggleButtonGroup
          value={selection}
          exclusive
          onChange={(event, value) => setSelection(value)}
          aria-label="text alignment"
        >
          <ToggleButton value="weeks" aria-label="weeks">
            Last week
          </ToggleButton>
          <ToggleButton value="months" aria-label="months">
            Last month
          </ToggleButton>
          {/* <ToggleButton value="years" aria-label="years">
            Last year
          </ToggleButton> */}
        </ToggleButtonGroup>
        <ReactApexChart
          type="line"
          series={CHART_DATA}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
};

export default AppWebsiteVisits;
