import { Card, CardContent, Container, Stack } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import PageHeader from "../../../components/PageHeader";
import * as Yup from "yup";
import apiRoutes from "../../../routes/apiRoutes";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { can } from "../../../components/helpers/Authorization";
import permissions from "../../../components/permissions/permissions";
import api from "../../../components/helpers/api";
import MultiInput from "../../../components/forms/MultiInput";
import { swalError } from "../../../components/helpers";

const EditFAQ = (props) => {
  const history = useHistory();
  const faqId = props.match.params.id;
  const [faq, setFAQ] = useState({});

  const LoginSchema = Yup.object().shape({
    // name: Yup.string().required("Name is required"),
  });

  useEffect(() => {
    _getFAQ();
    // eslint-disable-next-line
  }, []);

  const _getFAQ = () => {
    api
      .get(apiRoutes.faq.show + "/" + faqId)
      .then((response) => {
        const data = response.data.data;
        setFAQ(data);
      })
      .catch(() => {
        swalError();
      });
  };

  const formik = useFormik({
    initialValues: {
      question: faq.question,
      answer: faq.answer,
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (data, { setErrors }) => {
      return api
        .patch(`${apiRoutes.faq.update}/${faq.id}`, data)
        .then(() => {
          Swal.fire("Success", "Updated Successfully", "success");
          history.push(`/faq`);
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ name: ["Something went wrong"] });
          }
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    values,
    setValues,
  } = formik;

  const handleSetValue = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  return (
    <Page title="Edit FAQ">
      <PageHeader
        title="Edit FAQ"
        homeLink={can(permissions.settings.view) && `/faq`}
        homeText="FAQ"
        createLink={can(permissions.settings.create) && `/faq/create`}
        createText="Create"
      />
      <Container>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <MultiInput
                    fullWidth
                    type="text"
                    label="Question"
                    {...getFieldProps("question")}
                    required
                    handleSetValue={handleSetValue}
                    error={Boolean(
                      (touched.question && errors.question) ||
                        Object.keys(errors).filter((error) =>
                          /question/.test(error)
                        ).length
                    )}
                    helperText={touched.question && errors.question}
                  />
                  <MultiInput
                    fullWidth
                    type="text"
                    label="Answer"
                    multiline
                    maxRows={4}
                    rows={4}
                    {...getFieldProps("answer")}
                    required
                    handleSetValue={handleSetValue}
                    error={Boolean(
                      (touched.answer && errors.answer) ||
                        Object.keys(errors).filter((error) =>
                          /answer/.test(error)
                        ).length
                    )}
                    helperText={touched.answer && errors.answer}
                  />
                </Stack>

                <LoadingButton
                  fullWidth
                  sx={{ marginTop: 2 }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default EditFAQ;
