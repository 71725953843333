import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import editFill from "@iconify/icons-eva/edit-fill";
import fileFill from "@iconify/icons-eva/file-fill";
import { Link as RouterLink } from "react-router-dom";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Swal from "sweetalert2";
import api from "../../helpers/api";
import { swalError } from "../../helpers";

// ----------------------------------------------------------------------

const MoreMenu = (props) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleDelete = () => {
    if (window.confirm("Are you sure?")) {
      const { deleteLink, noDeleteMethod } = props;
      if (noDeleteMethod) {
        api
          .post(deleteLink)
          .then(() => {
            Swal.fire("Success", "Deleted Successfully", "success");
            props.removeItem();
          })
          .catch(() => {
            swalError();
          });
      } else {
        api
          .delete(deleteLink)
          .then(() => {
            Swal.fire("Success", "Deleted Successfully", "success");
            props.removeItem();
          })
          .catch(() => {
            swalError();
          });
      }
    }
  };

  const { children, editLink, showLink, deleteLink } = props;

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {editLink && (
          <MenuItem
            component={RouterLink}
            to={editLink}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        {showLink && (
          <MenuItem
            component={RouterLink}
            to={showLink}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Icon icon={fileFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Show"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        {children}

        {deleteLink && (
          <MenuItem sx={{ color: "text.secondary" }} onClick={handleDelete}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default MoreMenu;
