import {
  Card,
  CardContent,
  Container,
  List,
  ListSubheader,
} from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import api from "../../components/helpers/api";
import { can } from "../../components/helpers/Authorization";
import { getFirstValue, swalError } from "../../components/helpers";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import permissions from "../../components/permissions/permissions";
import apiRoutes from "../../routes/apiRoutes";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import NavTabs from "../../components/NavTabs";
import LessonItem from "../../components/_dashboard/lessons/LessonItem";

export class Lessons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseId: this.props.match.params.id,
      course: {},
    };
  }

  componentDidMount() {
    this._getCourse();
    this._getLessons();
  }

  _getCourse = () => {
    const { courseId } = this.state;
    api
      .get(apiRoutes.courses.show + "/" + courseId)
      .then((response) => {
        const data = response.data.data;
        this.setState({ course: data });
      })
      .catch(() => {
        swalError();
      });
  };

  _getLessons = () => {
    const { courseId } = this.state;
    api
      .get(apiRoutes.lessons.all + `/${courseId}`)
      .then((response) => {
        this.props.setLessonsData(response.data.data);
      })
      .catch((error) => {
        swalError();
      });
  };

  handleDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const lessons = this.props.lessons;
    const lesson = lessons.find(
      (lesson) => lesson.id.toString() === draggableId
    );
    lessons.splice(source.index, 1);
    lessons.splice(destination.index, 0, lesson);
    api
      .patch(`${apiRoutes.lessons.resort}/${this.state.courseId}`, {
        lessons: this.props.lessons.map((lesson) => lesson.id),
      })
      .then((response) => {
        this.props.setLessonsData(response.data.data);
      })
      .catch(() => {
        swalError();
      });
  };

  render() {
    const { course, courseId } = this.state;
    return (
      <Page title={`Lessons of ${getFirstValue(course.title)}`}>
        <PageHeader
          title={`Lessons of ${getFirstValue(course.title)}`}
          createLink={
            can(permissions.lessons.create) && `/lessons/create/${courseId}`
          }
          createText="Create Lesson"
        />
        <Container>
          <NavTabs
            tabs={[
              {
                label: "Course info",
                href: `/courses/show/${courseId}`,
              },
              {
                label: "Lessons",
                href: `/lessons/${courseId}`,
              },
            ]}
          />
          <Card>
            <CardContent>
              <DragDropContext onDragEnd={this.handleDragEnd}>
                <Droppable
                  droppableId={`${courseId.toString()}`}
                  direction="vertical"
                >
                  {(provided) => (
                    <List
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      sx={{
                        width: "100%",
                        bgcolor: "background.card",
                      }}
                      subheader={<ListSubheader>Lessons</ListSubheader>}
                    >
                      {this.props.lessons.map((lesson, index) => (
                        <LessonItem
                          key={lesson.id}
                          lesson={lesson}
                          index={index}
                        />
                      ))}
                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
            </CardContent>
          </Card>
        </Container>
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lessons: state.lessons.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLessonsData: (data) =>
      dispatch({
        type: "SET_LESSONS_DATA",
        payload: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Lessons);
