import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  LinearProgress,
  Container,
  Card,
  CardContent,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import Page from "../../components/Page";
import apiRoutes from "../../routes/apiRoutes";
import store from "../../store";
import CreatedAt from "../../components/CreatedAt";
import { SearchToolBar, MoreMenu } from "../../components/_dashboard/user";
import PageHeader from "../../components/PageHeader";
import api from "../../components/helpers/api";
import { swalError } from "../../components/helpers";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import sendFill from "@iconify/icons-eva/paper-plane-fill";

const columns = [
  {
    name: "#",
    selector: "id",
    sortable: true,
    maxWidth: "60px",
  },
  {
    name: "Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "Email",
    selector: "email",
    sortable: true,
  },
  {
    name: "Message",
    selector: "message",
    sortable: true,
  },
  {
    name: "User",
    selector: "user",
    cell: (row) =>
      can(permissions.users.view) ? (
        <Link to={`/users/show/${row.user?.id}`}>{row.user?.name}</Link>
      ) : (
        <div>{row.user?.name}</div>
      ),
  },
  {
    name: "Created At",
    selector: "created_at",
    cell: (row) => CreatedAt(row),
  },
  {
    name: "Controls",
    selector: "actions",
    maxWidth: "60px",
    cell: (row) => (
      <MoreMenu
        showLink={can(permissions.messages.view) && `/messages/show/${row.id}`}
        removeItem={() => removeItem(row.id)}
        deleteLink={
          can(permissions.messages.delete) &&
          apiRoutes.messages.delete + "/" + row.id
        }
      >
        <MenuItem
          component={Link}
          to="#"
          onClick={(e) => {
            e.preventDefault();
            window.open(
              `https://mail.google.com/mail/?view=cm&fs=1&to=${row.email}`
            );
          }}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Icon icon={sendFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Reply"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </MoreMenu>
    ),
  },
];

const conditionalRowStyles = [
  {
    when: (row) => !row.read_at,
    style: {
      backgroundColor: "grey",
      userSelect: "none",
    },
  },
];

const removeItem = (id) => {
  const data = store.getState().messages.data.filter((admin) => {
    return admin.id !== id;
  });
  store.dispatch({ type: "SET_MESSAGES_DATA", payload: data });
};

const Messages = (props) => {
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <SearchToolBar
        filterText={filterText}
        onFilterText={(e) => setFilterText(e.target.value)}
      />
    );
  }, [filterText]);

  useEffect(() => {
    fetchData(1, perPage);
    // eslint-disable-next-line
  }, [perPage, filterText]);

  const fetchData = async (page, perPage) => {
    setLoading(true);

    api
      .get(
        apiRoutes.messages.all +
          `?page=${page}&per_page=${perPage}&search=${filterText}`
      )
      .then((response) => {
        props.setMessagesData(response.data.data);
        setTotalRows(response.data.meta.total);
        setPerPage(perPage);
      })
      .catch((error) => {
        swalError();
      });

    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchData(page, newPerPage);
  };

  return (
    <Page title="Messages">
      <PageHeader title="Messages" />
      <Container>
        <Card>
          <CardContent>
            <DataTable
              title="Messages Table"
              columns={columns}
              data={props.messages}
              progressPending={loading}
              pagination
              paginationServer
              highlightOnHover
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              progressComponent={<LinearProgress />}
              persistTableHead
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              conditionalRowStyles={conditionalRowStyles}
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    messages: state.messages.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessagesData: (data) =>
      dispatch({
        type: "SET_MESSAGES_DATA",
        payload: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
