import store from "../../store";
import { search } from ".";

export const can = permission => {
  const permissions = store.getState().auth.permissions;

  if (search(permissions, permission)) {
    return true;
  }
  return false;
};
