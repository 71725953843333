import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LinearProgress, Container, Card, CardContent } from "@mui/material";
import DataTable from "react-data-table-component";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import Page from "../../components/Page";
import apiRoutes from "../../routes/apiRoutes";
import CreatedAt from "../../components/CreatedAt";
import { SearchToolBar } from "../../components/_dashboard/user";
import PageHeader from "../../components/PageHeader";
import api from "../../components/helpers/api";
import {
  getFirstValue,
  roundCurrency,
  swalError,
} from "../../components/helpers";
import { Link } from "react-router-dom";

const columns = [
  {
    name: "#",
    selector: "id",
    sortable: true,
    maxWidth: "60px",
  },
  {
    name: "Price",
    selector: "price",
    sortable: true,
    cell: (row) => <div>{roundCurrency(row.price)}$</div>,
  },
  {
    name: "User",
    selector: "user",
    sortable: true,
    sortFunction: (row1, row2) => {
      return ("" + row1.user?.name).localeCompare(row2.user?.name);
    },
    cell: (row) =>
      can(permissions.users.view) ? (
        <Link to={`/users/show/${row.user?.id}`}>{row.user?.name}</Link>
      ) : (
        <div>{row.user?.name}</div>
      ),
  },
  {
    name: "Course",
    selector: "course",
    sortable: true,
    sortFunction: (row1, row2) => {
      return ("" + getFirstValue(row1.course?.title)).localeCompare(
        getFirstValue(row2.course?.title)
      );
    },
    cell: (row) =>
      can(permissions.courses.view) ? (
        <Link to={`/courses/show/${row.course?.id}`}>
          {getFirstValue(row.course?.title)}
        </Link>
      ) : (
        <div>{getFirstValue(row.course?.title)}</div>
      ),
  },
  {
    name: "Created At",
    selector: "created_at",
    cell: (row) => CreatedAt(row),
  },
];

const Enrollments = (props) => {
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(100);

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <SearchToolBar
        filterText={filterText}
        onFilterText={(e) => setFilterText(e.target.value)}
      />
    );
  }, [filterText]);

  useEffect(() => {
    fetchData(1, perPage);
    // eslint-disable-next-line
  }, [perPage, filterText]);

  const fetchData = async (page, perPage) => {
    setLoading(true);

    api
      .get(
        apiRoutes.enrollments.all +
          `?page=${page}&per_page=${perPage}&search=${filterText}`
      )
      .then((response) => {
        props.setEnrollmentsData(response.data.data);
        setTotalRows(response.data.meta.total);
        setPerPage(perPage);
      })
      .catch(() => {
        swalError();
      });

    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchData(page, newPerPage);
  };

  return (
    <Page title="Courses Enrollments">
      <PageHeader title="Courses Enrollments" />
      <Container>
        <Card>
          <CardContent>
            <DataTable
              title="Enrollments Table"
              columns={columns}
              data={props.enrollments}
              progressPending={loading}
              pagination
              paginationServer
              highlightOnHover
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              progressComponent={<LinearProgress />}
              persistTableHead
              paginationTotalRows={totalRows}
              paginationRowsPerPageOptions={[100]}
              paginationPerPage={100}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    enrollments: state.enrollments.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEnrollmentsData: (data) =>
      dispatch({
        type: "SET_ENROLLMENTS_DATA",
        payload: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Enrollments);
