import {
  Avatar,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import apiRoutes from "../../routes/apiRoutes";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import moment from "moment";
import api from "../../components/helpers/api";
import { swalError } from "../../components/helpers";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import { Icon } from "@iconify/react";
import CreatedAt from "../../components/CreatedAt";

const ShowBlog = (props) => {
  const blogId = props.match.params.id;
  const [blog, setBlog] = useState({});

  useEffect(() => {
    _getBlog();
    // eslint-disable-next-line
  }, []);

  const _getBlog = () => {
    api
      .get(apiRoutes.blog.show + "/" + blogId)
      .then((response) => {
        const data = response.data.data;
        setBlog(data);
      })
      .catch(() => {
        swalError();
      });
  };

  const CoverImgStyle = styled("img")({
    width: "100%",
    height: "100%",
    objectFit: "cover",
  });

  return (
    <Page title="Show Blog">
      <PageHeader
        title="Show Blog"
        homeLink={can(permissions.blog.view) && "/blog"}
        homeText="Blog"
        createLink={can(permissions.blog.create) && "/blog/create"}
        createText="Create"
        editLink={can(permissions.blog.update) && `/blog/edit/${blog.id}`}
        editText="Edit"
      />
      <Container>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <h1>
                {blog.title} (
                <Typography
                  component="span"
                  sx={{ fontSize: "inherit" }}
                  color="text.secondary"
                >
                  {blog.headline}
                </Typography>
                )
              </h1>
              <CoverImgStyle
                src={`${blog.image?.image_url}`}
                srcSet={`${blog.image?.image_url}`}
                alt={blog.title}
                loading="lazy"
              />

              <Card>
                <CardContent>
                  <List
                    sx={{
                      width: "100%",
                    }}
                  >
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt={blog.author_name}
                          src={blog?.author_image?.thumb_url}
                          sx={{ width: 80, height: 80 }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={blog.author_name}
                        secondary={
                          <Typography
                            sx={{ whiteSpace: "pre-wrap" }}
                            color="text.secondary"
                          >
                            {blog.author_details}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>

              <Card>
                <CardContent>
                  <div dangerouslySetInnerHTML={{ __html: blog.contents }} />
                </CardContent>
              </Card>

              <Grid container spacing={3}>
                <Grid item xs={2}>
                  Tags
                </Grid>
                <Grid item xs={10}>
                  {blog.tags?.split(",").map((tag, index) => (
                    <Chip
                      avatar={<Avatar>#</Avatar>}
                      clickable
                      label={`${tag}`}
                      key={index}
                      sx={{ marginRight: 1 }}
                    />
                  ))}
                </Grid>
                <Grid item xs={2}>
                  Published
                </Grid>
                <Grid item xs={10}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: blog.published_at ? "green" : "grey.500",
                    }}
                  >
                    <Box
                      component={Icon}
                      icon={"eva:checkmark-circle-2-fill"}
                      sx={{ width: 16, height: 16, mr: 0.5 }}
                    />
                    <Typography variant="caption">
                      {blog.published_at
                        ? `Published at ${CreatedAt(blog.published_at)}`
                        : "Not published"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={2}>
                  Created at
                </Grid>
                <Grid item xs={10}>
                  {moment(blog.created_at).fromNow()}
                </Grid>
              </Grid>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default ShowBlog;
