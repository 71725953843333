import {
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import languages from "./languages";

const MultiInputFile = (props) => {
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const [inputValue, setInputValue] = useState({});
  const [fileValue, setFileValue] = useState({});
  const [files, setFiles] = useState({});
  const { fileType } = props;

  useEffect(() => {
    setInputValue(props.value ?? {});
  }, [props.value]);

  const handelSelectChange = (event) => {
    setCurrentLanguage(event.target.value);
  };
  const handelInputChange = (event) => {
    setInputValue({ ...inputValue, [currentLanguage]: event.target.value });
    props.handleSetValue(event.target.name, {
      ...inputValue,
      [currentLanguage]: event.target.value,
    });
  };

  const handelFileChange = (event) => {
    setFileValue({ ...fileValue, [currentLanguage]: event.target.value });
    setFiles({ ...files, [currentLanguage]: event.target.files[0] });
    props.handleSetValue(event.target.name, {
      ...files,
      [currentLanguage]: event.target.files[0],
    });
  };

  return (
    <Stack>
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Language</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentLanguage}
              label="Language"
              onChange={handelSelectChange}
            >
              {languages.map((option, idx) => (
                <MenuItem value={option.lang} key={idx}>
                  {option.language}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={10}>
          <TextField
            {...props}
            name="title"
            value={inputValue[currentLanguage] ?? ""}
            onChange={handelInputChange}
          />
        </Grid>
      </Grid>
      <FormControl>
        <FormLabel>File *</FormLabel>
        <input
          type="file"
          fullWidth
          name="file"
          accept={fileType}
          // value={fileValue[currentLanguage] ?? ""}
          onChange={handelFileChange}
        />
      </FormControl>
      <List>
        {Object.keys(fileValue).map((key) => (
          <ListItem key={key} sx={{ borderBottom: "1px solid #eee" }}>
            <ListItemText
              id="switch-list-label"
              primary={`${
                languages.find((lang) => lang.lang === key).language
              }: ${inputValue[key]}`}
              secondary={`${fileValue[key].split("\\")[2]}`}
            />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

export default MultiInputFile;
