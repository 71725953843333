import { Card, CardContent, Container, Grid } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import api from "../../components/helpers/api";
import { can } from "../../components/helpers/Authorization";
import { getFirstValue, swalError } from "../../components/helpers";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import permissions from "../../components/permissions/permissions";
import apiRoutes from "../../routes/apiRoutes";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { CourseCard } from "../../components/_dashboard/blog";

export class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryId: this.props.match.params.id,
      category: {},
    };
  }

  componentDidMount() {
    this._getCategory();
    this._getCourses();
  }

  _getCategory = () => {
    const { categoryId } = this.state;
    api
      .get(apiRoutes.categories.show + "/" + categoryId)
      .then((response) => {
        const data = response.data.data;
        this.setState({ category: data });
      })
      .catch(() => {
        swalError();
      });
  };

  _getCourses = () => {
    const { categoryId } = this.state;
    api
      .get(apiRoutes.courses.all + `/${categoryId}`)
      .then((response) => {
        this.props.setCoursesData(response.data.data);
      })
      .catch((error) => {
        swalError();
      });
  };

  handleDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const courses = this.props.courses;
    const course = courses.find(
      (course) => course.id.toString() === draggableId
    );
    courses.splice(source.index, 1);
    courses.splice(destination.index, 0, course);
    api
      .patch(`${apiRoutes.courses.resort}/${this.state.categoryId}`, {
        courses: this.props.courses.map((course) => course.id),
      })
      .then((response) => {
        this.props.setCoursesData(response.data.data);
      })
      .catch((error) => {
        swalError();
      });
  };

  render() {
    const { category, categoryId } = this.state;
    return (
      <Page title={`Courses of ${getFirstValue(category.title)}`}>
        <PageHeader
          title={`Courses of ${getFirstValue(category.title)}`}
          createLink={
            can(permissions.courses.create) && `/courses/create/${categoryId}`
          }
          createText="Create Course"
        />
        <Container>
          <Card>
            <CardContent>
              <DragDropContext onDragEnd={this.handleDragEnd}>
                <Droppable
                  droppableId={`${categoryId.toString()}`}
                  direction="vertical"
                >
                  {(provided) => (
                    <Grid
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      container
                      spacing={3}
                    >
                      {this.props.courses.map((course, index) => (
                        <CourseCard
                          key={course.id}
                          course={course}
                          index={index}
                        />
                      ))}
                      {provided.placeholder}
                    </Grid>
                  )}
                </Droppable>
              </DragDropContext>
            </CardContent>
          </Card>
        </Container>
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    courses: state.courses.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCoursesData: (data) =>
      dispatch({
        type: "SET_COURSES_DATA",
        payload: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
