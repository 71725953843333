import axios from "axios";
import cookie from "js-cookie";
import store from "../../store";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const logout = () => {
  cookie.remove("token");
  store.dispatch({ type: "SET_LOGOUT" });
  window.location = "/login";
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
    // whatever you want to do with the error
    const { response } = error;
    if (
      response &&
      response.data.message &&
      response.data.message === "Unauthenticated."
    )
      logout();
    return Promise.reject(error);
  }
);

export default api;
