// material
import { Box, Container, Typography, Grid } from "@mui/material";
// components
import Page from "../components/Page";
import AppCounts from "../components/_dashboard/app/AppCounts";
import {
  AppUsersCountries,
  AppWebsiteVisits,
  AppCourseEnrollments,
} from "../components/_dashboard/app";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <AppCounts />
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppUsersCountries />
          </Grid>
          <Grid item xs={12}>
            <AppCourseEnrollments />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
