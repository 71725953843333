import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LinearProgress, Container, Card, CardContent } from "@mui/material";
import DataTable from "react-data-table-component";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import Page from "../../components/Page";
import apiRoutes from "../../routes/apiRoutes";
import store from "../../store";
import CreatedAt from "../../components/CreatedAt";
import { SearchToolBar, MoreMenu } from "../../components/_dashboard/user";
import PageHeader from "../../components/PageHeader";
import api from "../../components/helpers/api";
import { getFirstValue, swalError } from "../../components/helpers";
import Activate from "../../components/Activate";
import { Link } from "react-router-dom";

const removeItem = (id) => {
  const data = store.getState().lesson_comments.data.filter((admin) => {
    return admin.id !== id;
  });
  store.dispatch({ type: "SET_LESSON_COMMENTS_DATA", payload: data });
};

const LessonComments = (props) => {
  const columns = [
    {
      name: "#",
      selector: "id",
      sortable: true,
      maxWidth: "60px",
    },
    {
      name: "Comment",
      selector: "contents",
      sortable: true,
      wrap: true,
    },
    {
      name: "User",
      selector: "user",
      cell: (row) =>
        can(permissions.users.view) ? (
          <Link to={`/users/show/${row.user?.id}`}>{row.user?.name}</Link>
        ) : (
          <div>{row.user?.name}</div>
        ),
    },
    {
      name: "Lesson",
      selector: "lesson",
      cell: (row) =>
        can(permissions.lessons.view) ? (
          <Link to={`/lessons/show/${row.lesson?.id}`}>
            {getFirstValue(row.lesson?.title)}
          </Link>
        ) : (
          <div>{getFirstValue(row.lesson?.title)}</div>
        ),
    },
    {
      name: "Created At",
      selector: "created_at",
      cell: (row) => CreatedAt(row),
    },
    {
      name: "Approve",
      selector: "approve",
      maxWidth: "60px",
      cell: (row) =>
        can(permissions.lesson_comments.update) && (
          <Activate
            url={apiRoutes.lesson_comments.approve + "/" + row.id}
            checked={row.approved_at}
            activeTitle="Approved"
            inactiveTitle="Disapproved"
          />
        ),
    },
    {
      name: "Ative",
      selector: "active",
      maxWidth: "60px",
      cell: (row) =>
        can(permissions.lesson_comments.update) && (
          <Activate
            url={apiRoutes.lesson_comments.activate + "/" + row.id}
            checked={row.active}
          />
        ),
    },
    {
      name: "Controls",
      selector: "actions",
      maxWidth: "60px",
      cell: (row) => (
        <MoreMenu
          removeItem={() => removeItem(row.id)}
          deleteLink={
            can(permissions.lesson_comments.delete) &&
            apiRoutes.lesson_comments.delete + "/" + row.id
          }
        />
      ),
    },
  ];
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <SearchToolBar
        filterText={filterText}
        onFilterText={(e) => setFilterText(e.target.value)}
      />
    );
  }, [filterText]);

  useEffect(() => {
    fetchData(1, perPage);
    // eslint-disable-next-line
  }, [perPage, filterText]);

  const fetchData = async (page, perPage) => {
    setLoading(true);

    api
      .get(
        apiRoutes.lesson_comments.all +
          `?page=${page}&per_page=${perPage}&search=${filterText}`
      )
      .then((response) => {
        props.setCommentsData(response.data.data);
        setTotalRows(response.data.meta.total);
        setPerPage(perPage);
      })
      .catch((error) => {
        swalError();
      });

    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchData(page, newPerPage);
  };

  return (
    <Page title="Lesson Comments">
      <PageHeader title="Lesson Comments" />
      <Container>
        <Card>
          <CardContent>
            <DataTable
              title="Comments Table"
              columns={columns}
              data={props.lesson_comments}
              progressPending={loading}
              pagination
              paginationServer
              highlightOnHover
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              progressComponent={<LinearProgress />}
              persistTableHead
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    lesson_comments: state.lesson_comments.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCommentsData: (data) =>
      dispatch({
        type: "SET_LESSON_COMMENTS_DATA",
        payload: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonComments);
