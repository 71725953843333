const SettingsReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_SOCIAL_MEDIA_DATA":
      return { ...state, socialMedia: actions.payload };

    case "SET_SITE_POLICIES_DATA":
      return { ...state, sitePolicies: actions.payload };

    case "SET_FAQ_DATA":
      return { ...state, faq: actions.payload };

    default:
      return state;
  }
};

export default SettingsReducer;
