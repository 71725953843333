import * as Yup from "yup";
import { Redirect, useHistory } from "react-router";
import { useFormik, Form, FormikProvider } from "formik";
import { InputAdornment, Stack, TextField, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import apiRoutes from "../../../routes/apiRoutes";
import { useState } from "react";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { Box } from "@mui/material";
import { storeLoginToken } from "../../helpers";
import { connect } from "react-redux";
import api from "../../helpers/api";

const useQuery = () => {
  return new URLSearchParams(window.location.search);
};

const ResetPasswordForm = (props) => {
  const history = useHistory();
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    password: Yup.string().required("Password is required").min(8),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const formik = useFormik({
    initialValues: {
      email: useQuery().get("email"),
      code: useQuery().get("code"),
      password: "",
      password_confirmation: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (data, { setErrors }) => {
      return api
        .post(`${apiRoutes.auth.password.reset}`, data)
        .then((res) => {
          storeLoginToken(res.data.access_token);
          props.setLogin(res.data.user);
          history.push("/home");
          window.location.reload();
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ password: ["Something went wrong"] });
          }
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  let query = useQuery();
  const email = query.get("email");

  if (!email) {
    return <Redirect to="/" />;
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="New Password"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword((show) => !show)}
                    edge="end"
                  >
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPasswordConfirmation ? "text" : "password"}
            label="Password Confirmation"
            {...getFieldProps("password_confirmation")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPasswordConfirmation((show) => !show)}
                    edge="end"
                  >
                    <Icon
                      icon={showPasswordConfirmation ? eyeFill : eyeOffFill}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(
              touched.password_confirmation && errors.password_confirmation
            )}
            helperText={
              touched.password_confirmation && errors.password_confirmation
            }
          />
        </Stack>

        <Box sx={{ marginTop: 2 }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Reset Password
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (user) =>
      dispatch({
        type: "SET_LOGIN",
        payload: user,
      }),
  };
};

export default connect(null, mapDispatchToProps)(ResetPasswordForm);
