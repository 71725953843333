import { Card, CardContent, Container, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import apiRoutes from "../../routes/apiRoutes";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import moment from "moment";
import api from "../../components/helpers/api";
import { swalError } from "../../components/helpers";

const ShowUser = (props) => {
  const adminId = props.match.params.id;
  const [admin, setAdmin] = useState({});

  useEffect(() => {
    _getAdmin();
    // eslint-disable-next-line
  }, []);

  const _getAdmin = () => {
    api
      .get(apiRoutes.users.show + "/" + adminId)
      .then((response) => {
        const data = response.data.data;
        setAdmin(data);
      })
      .catch((error) => {
        swalError();
      });
  };

  return (
    <Page title="Show User">
      <PageHeader
        title="Show User"
        homeLink={can(permissions.users.view) && "/users"}
        homeText="Users"
        createLink={can(permissions.users.create) && "/users/create"}
        createText="Create"
        editLink={can(permissions.users.update) && `/users/edit/${admin.id}`}
        editText="Edit"
      />
      <Container>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <h1>{admin.name}</h1>
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  Email Address
                </Grid>
                <Grid item xs={10}>
                  {admin.email}
                </Grid>
                <Grid item xs={2}>
                  Birth date
                </Grid>
                <Grid item xs={10}>
                  {moment(admin.birth_date).format("DD-MM-YYYY")}
                </Grid>
                <Grid item xs={2}>
                  Language
                </Grid>
                <Grid item xs={10}>
                  {admin.lang}
                </Grid>
                <Grid item xs={2}>
                  Country
                </Grid>
                <Grid item xs={10}>
                  {admin.country}
                </Grid>
                <Grid item xs={2}>
                  Mobile number
                </Grid>
                <Grid item xs={10}>
                  {admin.mobile_number}
                </Grid>
                <Grid item xs={2}>
                  Created at
                </Grid>
                <Grid item xs={10}>
                  {moment(admin.created_at).fromNow()}
                </Grid>
              </Grid>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default ShowUser;
