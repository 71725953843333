import {
  Card,
  CardContent,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import * as Yup from "yup";
import apiRoutes from "../../routes/apiRoutes";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import api from "../../components/helpers/api";

const EditUserPassword = (props) => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const adminId = props.match.params.id;

  const LoginSchema = Yup.object().shape({
    password: Yup.string().required("Password is required").min(8),
  });

  const handleShowPasswordConfirmation = () => {
    setShowPasswordConfirmation((show) => !show);
  };
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (data, { setErrors }) => {
      return api
        .patch(`${apiRoutes.users.password}/${adminId}`, data)
        .then((res) => {
          Swal.fire("Success", "Updated Successfully", "success");
          history.push("/users");
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ password: ["Something went wrong"] });
          }
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Edit User Password">
      <PageHeader
        title="Edit User Password"
        homeLink={can(permissions.users.view) && "/users"}
        homeText="Users"
        createLink={can(permissions.users.create) && "/users/create"}
        createText="Create"
        showLink={can(permissions.users.view) && `/users/show/${adminId}`}
        showText="Show"
        editLink={can(permissions.users.update) && `/users/edit/${adminId}`}
        editText="Edit"
      />
      <Container>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    {...getFieldProps("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    type={showPasswordConfirmation ? "text" : "password"}
                    label="Password confirmation"
                    {...getFieldProps("password_confirmation")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleShowPasswordConfirmation}
                            edge="end"
                          >
                            <Icon
                              icon={
                                showPasswordConfirmation ? eyeFill : eyeOffFill
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      touched.password_confirmation &&
                        errors.password_confirmation
                    )}
                    helperText={
                      touched.password_confirmation &&
                      errors.password_confirmation
                    }
                  />
                </Stack>

                <LoadingButton
                  fullWidth
                  sx={{ marginTop: 2 }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default EditUserPassword;
