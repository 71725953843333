import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import RolesReducer from "./RolesReducer";
import AdminsReducer from "./AdminsReducer";
import UsersReducer from "./UsersReducer";
import CategoriesReducer from "./CategoriesReducer";
import CoursesReducer from "./CoursesReducer";
import LessonsReducer from "./LessonsReducer";
import LessonCommentsReducer from "./LessonCommentsReducer";
import EnrollmentsReducer from "./EnrollmentsReducer";
import MessagesReducer from "./MessagesReducer";
import VisitorsReducer from "./VisitorsReducer";
import SettingsReducer from "./SettingsReducer";
import BlogCommentsReducer from "./BlogCommentsReducer";
import BlogReducer from "./BlogReducer";
import SidebarReducer from "./SidebarReducer";

const AllReducers = combineReducers({
  auth: AuthReducer,
  roles: RolesReducer,
  admins: AdminsReducer,
  users: UsersReducer,
  categories: CategoriesReducer,
  courses: CoursesReducer,
  lessons: LessonsReducer,
  lesson_comments: LessonCommentsReducer,
  blog: BlogReducer,
  blog_comments: BlogCommentsReducer,
  enrollments: EnrollmentsReducer,
  messages: MessagesReducer,
  visitors: VisitorsReducer,
  settings: SettingsReducer,
  sidebar: SidebarReducer,
});

export default AllReducers;
