import React, { useState } from "react";
import { Switch, Tooltip } from "@mui/material";
import api from "./helpers/api";
import { swalError } from "./helpers";

const Activate = (props) => {
  const [checked, setChecked] = useState(props.checked);

  const handleChange = () => {
    if (window.confirm("Are you sure?")) {
      api
        .patch(props.url)
        .then(() => {
          setChecked(!checked);
        })
        .catch(() => {
          swalError();
        });
    }
  };

  return (
    <div>
      <Tooltip
        title={
          checked
            ? props.activeTitle ?? "Active"
            : props.inactiveTitle ?? "Inavtive"
        }
        placement="top"
      >
        <Switch
          checked={!!checked}
          onChange={handleChange}
          value={props.checked}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </Tooltip>
    </div>
  );
};

export default Activate;
