const apiRoutes = {
  auth: {
    login: "api/admin/login",
    logout: "api/admin/logout",
    me: "api/admin/me",
    permissions: "api/admin/auth/permissions",
    password: {
      forget: "api/admin/forget/password",
      check: "api/admin/check/password/code",
      reset: "api/admin/reset/password",
    },
  },
  admins: {
    all: "api/admin/admins/all",
    create: "api/admin/admins/create",
    update: "api/admin/admins/update",
    password: "api/admin/admins/update/password",
    delete: "api/admin/admins/delete",
    show: "api/admin/admins/show",
    roles: "api/admin/admins/roles",
    activate: "api/admin/admins/active_toggle",
  },
  users: {
    all: "api/admin/users/all",
    create: "api/admin/users/create",
    update: "api/admin/users/update",
    password: "api/admin/users/update/password",
    delete: "api/admin/users/delete",
    show: "api/admin/users/show",
    activate: "api/admin/users/active_toggle",
  },
  roles: {
    all: "api/admin/roles/all",
    create: "api/admin/roles/create",
    update: "api/admin/roles/update",
    delete: "api/admin/roles/delete",
    show: "api/admin/roles/show",
    permissions: "api/admin/roles/permissions",
  },
  categories: {
    all: "api/admin/categories/all",
    create: "api/admin/categories/create",
    update: "api/admin/categories/update",
    delete: "api/admin/categories/delete",
    deleteImage: "api/admin/categories/delete/image",
    deleteVideo: "api/admin/categories/delete/video",
    show: "api/admin/categories/show",
    activate: "api/admin/categories/active_toggle",
    nav_view: "api/admin/categories/nav_view_toggle",
    up: "api/admin/categories/up",
    down: "api/admin/categories/down",
  },
  courses: {
    all: "api/admin/courses/all",
    create: "api/admin/courses/create",
    update: "api/admin/courses/update",
    delete: "api/admin/courses/delete",
    deleteVideo: "api/admin/courses/delete/video",
    show: "api/admin/courses/show",
    publish: "api/admin/courses/publish_toggle",
    homeToggle: "api/admin/courses/home_toggle",
    downloadableToggle: "api/admin/courses/downloadable_toggle",
    resort: "api/admin/courses/resort",
  },
  enrollments: {
    all: "api/admin/enrollments/all",
  },
  visitors: {
    all: "api/admin/visitors/all",
  },
  lessons: {
    all: "api/admin/lessons/all",
    create: "api/admin/lessons/create",
    update: "api/admin/lessons/update",
    delete: "api/admin/lessons/delete",
    show: "api/admin/lessons/show",
    publish: "api/admin/lessons/publish_toggle",
    resort: "api/admin/lessons/resort",
    pay: "api/admin/lessons/pay",
  },
  lesson_comments: {
    all: "api/admin/comments/all",
    delete: "api/admin/comments/delete",
    activate: "api/admin/comments/active_toggle",
    approve: "api/admin/comments/approve_toggle",
  },
  files: {
    create: "api/admin/files/create",
    addTranslation: "api/admin/files/add/translation",
    delete: "api/admin/files/delete",
    deleteOne: "api/admin/files/delete/one",
    activate: "api/admin/files/active_toggle",
    resort: "api/admin/files/resort",
  },
  videos: {
    create: "api/admin/videos/create",
    addTranslation: "api/admin/videos/add/translation",
    delete: "api/admin/videos/delete",
    deleteOne: "api/admin/videos/delete/one",
    activate: "api/admin/videos/active_toggle",
    resort: "api/admin/videos/resort",
  },
  audios: {
    create: "api/admin/audios/create",
    addTranslation: "api/admin/audios/add/translation",
    delete: "api/admin/audios/delete",
    deleteOne: "api/admin/audios/delete/one",
    activate: "api/admin/audios/active_toggle",
    resort: "api/admin/audios/resort",
  },
  messages: {
    all: "api/admin/contact/all",
    delete: "api/admin/contact/delete",
    show: "api/admin/contact/show",
    markRead: "api/admin/contact/mark_read",
  },
  socialMedia: {
    all: "api/admin/social_media/all",
    create: "api/admin/social_media/create",
    update: "api/admin/social_media/update",
    delete: "api/admin/social_media/delete",
    show: "api/admin/social_media/show",
  },
  faq: {
    all: "api/admin/faq/all",
    create: "api/admin/faq/create",
    update: "api/admin/faq/update",
    delete: "api/admin/faq/delete",
    show: "api/admin/faq/show",
  },
  sitePolicies: {
    all: "api/admin/site_policies/all",
    aboutUs: "api/admin/site_policies/about_us",
    privacyPolicy: "api/admin/site_policies/privacy_policy",
    termsAndConditions: "api/admin/site_policies/terms_and_conditions",
  },
  profile: {
    changePassword: "api/admin/profile/password/change",
  },
  blog: {
    all: "api/admin/blog/all",
    create: "api/admin/blog/create",
    update: "api/admin/blog/update",
    delete: "api/admin/blog/delete",
    show: "api/admin/blog/show",
    publish: "api/admin/blog/publish_toggle",
  },
  blog_comments: {
    all: "api/admin/blog_comments/all",
    delete: "api/admin/blog_comments/delete",
    activate: "api/admin/blog_comments/active_toggle",
    approve: "api/admin/blog_comments/approve_toggle",
  },
  dashboard: {
    counts: "api/admin/dashboard/counts",
    visits: "api/admin/dashboard/visits",
    countries: "api/admin/dashboard/countries",
    enrollments: "api/admin/dashboard/enrollments",
    sidebar: "api/admin/dashboard/sidebar",
  },
};

export default apiRoutes;
