const permissions = {
  admins: {
    create: "create admins",
    update: "update admins",
    view: "view admins",
    delete: "delete admins",
  },
  roles: {
    create: "create roles",
    update: "update roles",
    view: "view roles",
    delete: "delete roles",
  },
  users: {
    create: "create users",
    update: "update users",
    view: "view users",
    delete: "delete users",
  },
  categories: {
    create: "create categories",
    update: "update categories",
    view: "view categories",
    delete: "delete categories",
  },
  courses: {
    create: "create courses",
    update: "update courses",
    view: "view courses",
    delete: "delete courses",
  },
  lessons: {
    create: "create lessons",
    update: "update lessons",
    view: "view lessons",
    delete: "delete lessons",
  },
  lesson_comments: {
    create: "create comments",
    update: "update comments",
    view: "view comments",
    delete: "delete comments",
  },
  blog_comments: {
    create: "create blog_comments",
    update: "update blog_comments",
    view: "view blog_comments",
    delete: "delete blog_comments",
  },
  blog: {
    create: "create blog",
    update: "update blog",
    view: "view blog",
    delete: "delete blog",
  },
  messages: {
    view: "view messages",
    delete: "delete messages",
  },
  enrollments: {
    view: "view enrollments",
  },
  visitors: {
    view: "view visitors",
  },
  settings: {
    create: "create settings",
    update: "update settings",
    view: "view settings",
    delete: "delete settings",
  },
};

export default permissions;
