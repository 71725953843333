import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  LinearProgress,
  Container,
  Card,
  CardContent,
  Avatar,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import Page from "../../components/Page";
import apiRoutes from "../../routes/apiRoutes";
import store from "../../store";
import CreatedAt from "../../components/CreatedAt";
import { SearchToolBar, MoreMenu } from "../../components/_dashboard/user";
import PageHeader from "../../components/PageHeader";
import api from "../../components/helpers/api";
import Activate from "../../components/Activate";
import Reorder from "../../components/Reorder";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import bookFill from "@iconify/icons-eva/book-fill";
import { swalError } from "../../components/helpers";
import Swal from "sweetalert2";

const removeItem = (id) => {
  const data = store.getState().categories.data.filter((category) => {
    return category.id !== id;
  });
  store.dispatch({ type: "SET_CATEGORIES_DATA", payload: data });
};

const removeImage = (id) => {
  const data = store.getState().categories.data.map((category) => {
    return category.id === id ? { ...category, image: null } : category;
  });
  store.dispatch({ type: "SET_CATEGORIES_DATA", payload: data });
};

const removeVideo = (id) => {
  const data = store.getState().categories.data.map((category) => {
    return category.id === id ? { ...category, promo_video: null } : category;
  });
  store.dispatch({ type: "SET_CATEGORIES_DATA", payload: data });
};

const Categories = (props) => {
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const columns = [
    {
      name: "#",
      selector: "id",
      sortable: true,
      maxWidth: "60px",
    },
    {
      name: "Image",
      selector: "image",
      sortable: true,
      maxWidth: "60px",
      cell: (row) => <Avatar alt={row.title[0]} src={row.image?.thumb_url} />,
    },
    {
      name: "Title",
      selector: "title",
      sortable: true,
      cell: (row) => (
        <div>
          {Object.keys(row.title).map((key) => (
            <div key={key}>
              {key.toLocaleUpperCase()}: {row.title[key]}
            </div>
          ))}
        </div>
      ),
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
      cell: (row) => (
        <div>
          {Object.keys(row.description).map((key) => (
            <div key={key}>
              {key.toLocaleUpperCase()}: {row.description[key]}
            </div>
          ))}
        </div>
      ),
    },
    {
      name: "Promo Video",
      selector: "promo_video",
      sortable: true,
      cell: (row) =>
        row.promo_video && (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              window.open(`${row.promo_video}`);
            }}
          >
            Open Video
          </Link>
        ),
    },
    {
      name: "Active",
      selector: "active",
      maxWidth: "60px",
      cell: (row) =>
        can(permissions.categories.update) && (
          <Activate
            url={apiRoutes.categories.activate + "/" + row.id}
            checked={row.active}
          />
        ),
    },
    {
      name: "Navbar View",
      selector: "nav_view",
      maxWidth: "80px",
      cell: (row) =>
        can(permissions.categories.update) && (
          <Activate
            url={apiRoutes.categories.nav_view + "/" + row.id}
            checked={row.nav_view}
          />
        ),
    },
    {
      name: "Reorder",
      selector: "order",
      maxWidth: "80px",
      cell: (row) =>
        can(permissions.categories.update) && (
          <Reorder
            upUrl={apiRoutes.categories.up + "/" + row.id}
            downUrl={apiRoutes.categories.down + "/" + row.id}
            perPage={perPage}
          />
        ),
    },
    {
      name: "Created At",
      selector: "created_at",
      cell: (row) => CreatedAt(row),
    },
    {
      name: "Controls",
      selector: "actions",
      maxWidth: "60px",
      cell: (row) => {
        const handleDeleteImage = () => {
          api
            .delete(`${apiRoutes.categories.deleteImage}/${row.id}`, {})
            .then(() => {
              Swal.fire("Success", "Deleted Successfully", "success");
              removeImage(row.id);
            })
            .catch(() => {
              swalError();
            });
        };
        const handleDeleteVideo = () => {
          api
            .delete(`${apiRoutes.categories.deleteVideo}/${row.id}`, {})
            .then(() => {
              Swal.fire("Success", "Deleted Successfully", "success");
              removeVideo(row.id);
            })
            .catch(() => {
              swalError();
            });
        };
        return (
          <MoreMenu
            editLink={
              can(permissions.categories.update) && `/categories/edit/${row.id}`
            }
            removeItem={() => removeItem(row.id)}
            deleteLink={
              can(permissions.categories.delete) &&
              apiRoutes.categories.delete + "/" + row.id
            }
          >
            {can(permissions.courses.view) && (
              <MenuItem
                component={Link}
                to={`/courses/${row.id}`}
                sx={{ color: "text.secondary" }}
              >
                <ListItemIcon>
                  <Icon icon={bookFill} width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary="View Courses"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>
            )}
            {can(permissions.categories.delete) && row.image && (
              <MenuItem
                sx={{ color: "text.secondary" }}
                onClick={handleDeleteImage}
              >
                <ListItemIcon>
                  <Icon icon="bi:card-image" width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary="Delete Image"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>
            )}
            {can(permissions.categories.delete) && row.promo_video && (
              <MenuItem
                sx={{ color: "text.secondary" }}
                onClick={handleDeleteVideo}
              >
                <ListItemIcon>
                  <Icon icon="bi:camera-video-fill" width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary="Delete Video"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>
            )}
          </MoreMenu>
        );
      },
    },
  ];

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <SearchToolBar
        filterText={filterText}
        onFilterText={(e) => setFilterText(e.target.value)}
      />
    );
  }, [filterText]);

  useEffect(() => {
    fetchData(1, perPage);
    // eslint-disable-next-line
  }, [perPage, filterText]);

  const fetchData = async (page, perPage) => {
    setLoading(true);

    api
      .get(
        apiRoutes.categories.all +
          `?page=${page}&per_page=${perPage}&search=${filterText}`
      )
      .then((response) => {
        props.setCategoriessData(response.data.data);
        setTotalRows(response.data.meta.total);
        setPerPage(perPage);
      })
      .catch((error) => {
        swalError();
      });

    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchData(page, newPerPage);
  };

  return (
    <Page title="Categories">
      <PageHeader
        title="Categories"
        createLink={can(permissions.categories.create) && "/categories/create"}
        createText="Create Category"
      />
      <Container>
        <Card>
          <CardContent>
            <DataTable
              title="Categories Table"
              columns={columns}
              data={props.categories}
              progressPending={loading}
              pagination
              paginationServer
              highlightOnHover
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              progressComponent={<LinearProgress />}
              persistTableHead
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.categories.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCategoriessData: (data) =>
      dispatch({
        type: "SET_CATEGORIES_DATA",
        payload: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
