import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import {
  AppNewUsers,
  AppArticlesCount,
  AppCoursesCount,
  AppVisitorsCount,
} from "./";
import api from "../../helpers/api";
import apiRoutes from "../../../routes/apiRoutes";

const AppCounts = () => {
  const [users, setUsers] = useState(0);
  const [articles, setArticles] = useState(0);
  const [courses, setCourses] = useState(0);
  const [visitors, setVisitors] = useState(0);

  useEffect(() => {
    api
      .get(apiRoutes.dashboard.counts)
      .then((response) => {
        const { users, articles, courses, visitors } = response.data;
        setUsers(users);
        setVisitors(visitors);
        setArticles(articles);
        setCourses(courses);
      })
      .catch(() => {});
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <AppNewUsers count={users} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AppArticlesCount count={articles} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AppCoursesCount count={courses} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AppVisitorsCount count={visitors} />
      </Grid>
    </Grid>
  );
};

export default AppCounts;
