import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";
import { Redirect, useHistory } from "react-router";
import { useFormik, Form, FormikProvider } from "formik";
import { Link, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import apiRoutes from "../../../routes/apiRoutes";
import api from "../../helpers/api";

const useQuery = () => {
  return new URLSearchParams(window.location.search);
};

const CheckPasswordForm = () => {
  const history = useHistory();

  const LoginSchema = Yup.object().shape({
    code: Yup.string().required("Code is required").min(6),
  });

  const formik = useFormik({
    initialValues: {
      email: useQuery().get("email"),
      code: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (data, { setErrors }) => {
      return api
        .post(`${apiRoutes.auth.password.check}`, data)
        .then(() => {
          history.push(`/reset/password?email=${data.email}&code=${data.code}`);
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ code: ["Something went wrong"] });
          }
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  let query = useQuery();
  const email = query.get("email");

  if (!email) {
    return <Redirect to="/" />;
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="code"
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <Link
            component={RouterLink}
            variant="subtitle2"
            to="/forget/password"
          >
            Didn't receive your code?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Verify Password Code
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
};

export default CheckPasswordForm;
