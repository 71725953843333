import { Card, CardContent, Container, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import apiRoutes from "../../routes/apiRoutes";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import moment from "moment";
import api from "../../components/helpers/api";
import { swalError } from "../../components/helpers";

const ShowRole = (props) => {
  const roleId = props.match.params.id;
  const [role, setRole] = useState({});

  useEffect(() => {
    _getRole();
    // eslint-disable-next-line
  }, []);

  const _getRole = () => {
    api
      .get(apiRoutes.roles.show + "/" + roleId)
      .then((response) => {
        const data = response.data.data;
        setRole(data);
      })
      .catch(() => {
        swalError();
      });
  };

  return (
    <Page title="Show Role">
      <PageHeader
        title="Show Role"
        homeLink={can(permissions.roles.view) && "/roles"}
        homeText="Roles"
        createLink={can(permissions.roles.create) && "/roles/create"}
        createText="Create"
        editLink={can(permissions.roles.update) && `/roles/edit/${role.id}`}
        editText="Edit"
      />
      <Container>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <h1>{role.name}</h1>
              <Grid container spacing={3}>
                {role.permissions?.map((permission) => (
                  <Grid item xs={3} key={permission.id}>
                    {permission.name}
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  Created at
                </Grid>
                <Grid item xs={10}>
                  {moment(role.created_at).fromNow()}
                </Grid>
              </Grid>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default ShowRole;
