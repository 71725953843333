import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import languages from "./languages";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

class MultiEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: "en",
    };
    DecoupledEditor.defaultConfig = {
      toolbar: {
        items: [
          "bold",
          "italic",
          "|",
          "bulletedList",
          "numberedList",
          "indent",
          "outdent",
          "link",
          "blockQuote",
          "|",
          "heading",
          "|",
          "undo",
          "redo",
        ],
      },
    };
  }

  myEditor = null;

  handelSelectChange = (event) => {
    this.setState({ currentLanguage: event.target.value });
  };

  handleEditor = (event, editor) => {
    const { currentLanguage } = this.state;
    const data = editor.getData();
    if (data) {
      this.props.handleSetValue(this.props.name, {
        ...this.props.value,
        [currentLanguage]: data,
      });
    }
  };

  render() {
    const { currentLanguage } = this.state;
    return (
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Language</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentLanguage}
              label="Language"
              onChange={this.handelSelectChange}
            >
              {languages.map((option, idx) => (
                <MenuItem value={option.lang} key={idx}>
                  {option.language}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={10}>
          <FormControl error={this.props.error} fullWidth>
            <CKEditor
              onReady={(editor) => {
                console.log("Editor is ready to use!", editor);

                // Insert the toolbar before the editable area.
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  );

                this.myEditor = editor;
              }}
              onError={({ willEditorRestart }) => {
                // If the editor is restarted, the toolbar element will be created once again.
                // The `onReady` callback will be called again and the new toolbar will be added.
                // This is why you need to remove the older toolbar.
                if (willEditorRestart) {
                  this.myEditor.ui.view.toolbar.element.remove();
                }
              }}
              style={{ width: "100%" }}
              editor={DecoupledEditor}
              onChange={this.handleEditor}
              data={this.props.value[currentLanguage] ?? ""}
            />
            <FormHelperText>{this.props.helperText}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

export default MultiEditor;
