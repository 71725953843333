import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { Card, CardHeader, Box } from "@mui/material";
//
import { BaseOptionChart } from "../../charts";
import { useEffect, useState } from "react";
import api from "../../helpers/api";
import apiRoutes from "../../../routes/apiRoutes";
import { getFirstValue } from "../../helpers";

// ----------------------------------------------------------------------

const AppCourseEnrollments = () => {
  const [enrollments, setEnrollments] = useState([]);

  const CHART_DATA = [
    {
      name: "Enrollments",
      type: "column",
      data: enrollments.map((enrollment) => enrollment.count),
    },
  ];

  useEffect(() => {
    api
      .get(`${apiRoutes.dashboard.enrollments}`)
      .then((response) => setEnrollments(response.data.enrollments))
      .catch(() => {});
  }, []);

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [4] },
    plotOptions: { bar: { columnWidth: "11%", borderRadius: 4 } },
    fill: { type: ["solid"] },
    labels: enrollments.map((enrollment) =>
      getFirstValue(enrollment.course?.title)
    ),
    // xaxis: "string",
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)} enrollments`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card>
      <CardHeader title="Courses Enrollments" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="bar"
          series={CHART_DATA}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
};

export default AppCourseEnrollments;
