import { Card, CardContent, Container, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import PageHeader from "../../../components/PageHeader";
import apiRoutes from "../../../routes/apiRoutes";
import { can } from "../../../components/helpers/Authorization";
import permissions from "../../../components/permissions/permissions";
import moment from "moment";
import api from "../../../components/helpers/api";
import { swalError } from "../../../components/helpers";
import MultiLanguageTable from "../../../components/MultiLanguageTable";

const ShowFAQ = (props) => {
  const faqId = props.match.params.id;
  const [faq, setFAQ] = useState({});

  useEffect(() => {
    _getFAQ();
    // eslint-disable-next-line
  }, []);

  const _getFAQ = () => {
    api
      .get(apiRoutes.faq.show + "/" + faqId)
      .then((response) => {
        const data = response.data.data;
        setFAQ(data);
      })
      .catch(() => {
        swalError();
      });
  };

  return (
    <Page title="Show FAQ">
      <PageHeader
        title="Show FAQ"
        createLink={can(permissions.settings.create) && "/faq/create"}
        createText="Create"
        editLink={can(permissions.settings.update) && "/faq/edit/" + faq.id}
        editText="Edit"
        homeLink={can(permissions.settings.view) && "/faq"}
        homeText="FAQ"
      />
      <Container>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <MultiLanguageTable title="Question" content={faq.question} />
              <MultiLanguageTable title="Answer" content={faq.answer} />
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  Created at
                </Grid>
                <Grid item xs={10}>
                  {moment(faq.created_at).fromNow()}
                </Grid>
              </Grid>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default ShowFAQ;
