import { Card, CardContent, Container, Stack } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import * as Yup from "yup";
import apiRoutes from "../../routes/apiRoutes";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import api from "../../components/helpers/api";
import MultiInput from "../../components/forms/MultiInput";
import { swalError } from "../../components/helpers";

const EditLesson = (props) => {
  const history = useHistory();
  const lessonId = props.match.params.id;
  const [lesson, setLesson] = useState({});

  const LoginSchema = Yup.object().shape({
    // name: Yup.string().required("Name is required"),
  });

  useEffect(() => {
    _getLesson();
    // eslint-disable-next-line
  }, []);

  const _getLesson = () => {
    api
      .get(apiRoutes.lessons.show + "/" + lessonId)
      .then((response) => {
        const data = response.data.data;
        setLesson(data);
      })
      .catch(() => {
        swalError();
      });
  };

  const formik = useFormik({
    initialValues: {
      title: lesson.title,
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (data, { setErrors }) => {
      return api
        .patch(`${apiRoutes.lessons.update}/${lesson.id}`, data)
        .then((res) => {
          Swal.fire("Success", "Updated Successfully", "success");
          history.push(`/lessons/${lesson.course_id}`);
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ name: ["Something went wrong"] });
          }
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    values,
    setValues,
  } = formik;

  const handleSetValue = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  return (
    <Page title="Edit Lesson">
      <PageHeader
        title="Edit Lesson"
        homeLink={
          can(permissions.lessons.view) && `/lessons/${lesson.course_id}`
        }
        homeText="lessons"
        createLink={
          can(permissions.lessons.create) &&
          `/lessons/create/${lesson.course_id}`
        }
        createText="Create"
        showLink={can(permissions.lessons.view) && `/lessons/show/${lesson.id}`}
        showText="Show"
      />
      <Container>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <MultiInput
                    fullWidth
                    type="text"
                    label="Title"
                    {...getFieldProps("title")}
                    required
                    handleSetValue={handleSetValue}
                    error={Boolean(
                      (touched.title && errors.title) ||
                        Object.keys(errors).filter((error) =>
                          /title/.test(error)
                        ).length
                    )}
                    helperText={touched.title && errors.title}
                  />
                </Stack>

                <LoadingButton
                  fullWidth
                  sx={{ marginTop: 2 }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default EditLesson;
