import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  LinearProgress,
  Container,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import Page from "../../components/Page";
import apiRoutes from "../../routes/apiRoutes";
import store from "../../store";
import CreatedAt from "../../components/CreatedAt";
import Activate from "../../components/Activate";
import { SearchToolBar, MoreMenu } from "../../components/_dashboard/user";
import PageHeader from "../../components/PageHeader";
import api from "../../components/helpers/api";
import { swalError } from "../../components/helpers";
import NavTabs from "../../components/NavTabs";

const columns = [
  {
    name: "#",
    selector: "id",
    sortable: true,
    maxWidth: "60px",
  },
  {
    name: "Image",
    selector: "image",
    sortable: true,
    maxWidth: "60px",
    cell: (row) => <Avatar alt={row.title[0]} src={row.image?.thumb_url} />,
  },
  {
    name: "Title",
    selector: "title",
    sortable: true,
  },
  {
    name: "Author Name",
    selector: "author_name",
    sortable: true,
  },
  {
    name: "Headline",
    selector: "headline",
    sortable: true,
  },
  {
    name: "Created At",
    selector: "created_at",
    cell: (row) => CreatedAt(row),
  },
  {
    name: "Publish",
    selector: "published_at",
    maxWidth: "60px",
    cell: (row) =>
      can(permissions.blog.update) && (
        <Activate
          url={apiRoutes.blog.publish + "/" + row.id}
          checked={row.published_at}
          activeTitle="Published"
          inactiveTitle="Not published"
        />
      ),
  },
  {
    name: "Controls",
    selector: "actions",
    maxWidth: "60px",
    cell: (row) => (
      <MoreMenu
        editLink={can(permissions.blog.update) && `/blog/edit/${row.id}`}
        showLink={can(permissions.blog.view) && `/blog/show/${row.id}`}
        removeItem={() => removeItem(row.id)}
        deleteLink={
          can(permissions.blog.delete) && apiRoutes.blog.delete + "/" + row.id
        }
      />
    ),
  },
];

const removeItem = (id) => {
  const data = store.getState().blog.data.filter((admin) => {
    return admin.id !== id;
  });
  store.dispatch({ type: "SET_BLOG_DATA", payload: data });
};

const Blog = (props) => {
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <SearchToolBar
        filterText={filterText}
        onFilterText={(e) => setFilterText(e.target.value)}
      />
    );
  }, [filterText]);

  useEffect(() => {
    fetchData(1, perPage);
    // eslint-disable-next-line
  }, [perPage, filterText]);

  const fetchData = async (page, perPage) => {
    setLoading(true);

    api
      .get(
        apiRoutes.blog.all +
          `?page=${page}&per_page=${perPage}&search=${filterText}`
      )
      .then((response) => {
        props.setBlogData(response.data.data);
        setTotalRows(response.data.meta.total);
        setPerPage(perPage);
      })
      .catch((error) => {
        swalError();
      });

    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchData(page, newPerPage);
  };

  return (
    <Page title="Blog">
      <PageHeader
        title="Blog"
        createLink={can(permissions.blog.create) && "/blog/create"}
        createText="Create Blog"
      />
      <Container>
        <NavTabs
          tabs={[
            {
              label: "Blog",
              href: `/blog`,
            },
            {
              label: "Comments",
              href: `/blog_comments`,
            },
          ]}
        />
        <Card>
          <CardContent>
            <DataTable
              title="Blog Table"
              columns={columns}
              data={props.blog}
              progressPending={loading}
              pagination
              paginationServer
              highlightOnHover
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              progressComponent={<LinearProgress />}
              persistTableHead
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    blog: state.blog.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBlogData: (data) =>
      dispatch({
        type: "SET_BLOG_DATA",
        payload: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
