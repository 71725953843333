import {
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  TextField,
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import * as Yup from "yup";
import apiRoutes from "../../routes/apiRoutes";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import api from "../../components/helpers/api";
import { createFormData, swalError } from "../../components/helpers";
import TagsInput from "../../components/forms/TagsInput";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import MyCustomUploadAdapterPlugin from "../../components/forms/Editor";

const EditBlog = (props) => {
  const history = useHistory();
  const blogId = props.match.params.id;
  const [blog, setBlog] = useState({});
  const [contents, setContents] = useState("");
  var myEditor = null;

  const LoginSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    // contents: Yup.string().required("Content is required"),
    author_name: Yup.string().required("Author name is required"),
    tags: Yup.string().required("Tags are required"),
    headline: Yup.string().required("Headline is required"),
  });

  useEffect(() => {
    _getBlog();
    // eslint-disable-next-line
  }, []);

  const _getBlog = () => {
    api
      .get(apiRoutes.blog.show + "/" + blogId)
      .then((response) => {
        const data = response.data.data;
        setBlog(data);
        setContents(data.contents);
      })
      .catch(() => {
        swalError();
      });
  };

  const formik = useFormik({
    initialValues: {
      title: blog?.title,
      // contents: blog?.contents,
      author_name: blog?.author_name,
      author_details: blog?.author_details,
      tags: blog?.tags ?? "",
      headline: blog?.headline,
      image: "",
      author_image: "",
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (data, { setErrors }) => {
      return api
        .post(
          `${apiRoutes.blog.update}/${blog.id}`,
          createFormData(
            {
              image: [data.image],
              author_image: [data.author_image],
            },
            {
              title: data.title,
              contents: contents,
              author_name: data.author_name,
              author_details: data.author_details,
              tags: data.tags,
              headline: data.headline,
              _method: "PATCH",
            }
          )
        )
        .then(() => {
          Swal.fire("Success", "Updated Successfully", "success");
          history.push("/blog");
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ first_name: ["Something went wrong"] });
          }
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setValues,
    values,
  } = formik;

  return (
    <Page title="Edit Blog">
      <PageHeader
        title="Edit Blog"
        homeLink={can(permissions.blog.view) && "/blog"}
        homeText="Blog"
        createLink={can(permissions.blog.create) && "/blog/create"}
        createText="Create"
        showLink={can(permissions.blog.view) && `/blog/show/${blog.id}`}
        showText="Show"
      />
      <Container>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              {/* <Form autoComplete="off" noValidate onSubmit={handleSubmit}> */}
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  type="text"
                  label="Title"
                  required
                  {...getFieldProps("title")}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />
                <TextField
                  fullWidth
                  type="text"
                  label="Headline"
                  required
                  {...getFieldProps("headline")}
                  error={Boolean(touched.headline && errors.headline)}
                  helperText={touched.headline && errors.headline}
                />

                <TagsInput
                  selectedTags={(items) => {
                    setValues({ ...values, tags: items.join(",") });
                  }}
                  fullWidth
                  id="tags"
                  name="tags"
                  placeholder="add Tags"
                  label="Tags"
                  type="text"
                  required
                  tags={values.tags
                    .split(",")
                    .filter((word) => /\w/.test(word))}
                  error={Boolean(touched.tags && errors.tags)}
                  helperText={touched.tags && errors.tags}
                />

                <FormControl error={Boolean(touched.image && errors.image)}>
                  <FormLabel>Image</FormLabel>
                  <input
                    type="file"
                    fullWidth
                    required
                    label="Image"
                    accept="image/*"
                    onChange={(event) =>
                      setValues({ ...values, image: event.target.files[0] })
                    }
                  />
                  <FormHelperText>
                    {touched.image && errors.image}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  error={Boolean(touched.contents && errors.contents)}
                >
                  <FormLabel>Contents *</FormLabel>
                  <CKEditor
                    fullWidth
                    required
                    style={{ width: "100%" }}
                    onReady={(editor) => {
                      editor.ui
                        .getEditableElement()
                        .parentElement.insertBefore(
                          editor.ui.view.toolbar.element,
                          editor.ui.getEditableElement()
                        );

                      myEditor = editor;
                    }}
                    onError={({ willEditorRestart }) => {
                      if (willEditorRestart) {
                        myEditor.ui.view.toolbar.element.remove();
                      }
                    }}
                    editor={DecoupledEditor}
                    config={{
                      simpleUpload: {
                        uploadUrl: `${process.env.REACT_APP_API_URL}api/admin/blog/contents/image/upload`,
                      },
                      extraPlugins: [MyCustomUploadAdapterPlugin],
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      if (data) {
                        setContents(data);
                      }
                    }}
                    data={contents}
                  />
                  <FormHelperText>
                    {touched.contents && errors.contents}
                  </FormHelperText>
                </FormControl>

                <TextField
                  fullWidth
                  type="text"
                  label="Author Name"
                  required
                  {...getFieldProps("author_name")}
                  error={Boolean(touched.author_name && errors.author_name)}
                  helperText={touched.author_name && errors.author_name}
                />

                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  type="text"
                  label="Author Details"
                  {...getFieldProps("author_details")}
                  error={Boolean(
                    touched.author_details && errors.author_details
                  )}
                  helperText={touched.author_details && errors.author_details}
                />

                <FormControl
                  error={Boolean(touched.author_image && errors.author_image)}
                >
                  <FormLabel>Author Image</FormLabel>
                  <input
                    type="file"
                    fullWidth
                    label="Author Image"
                    accept="image/*"
                    onChange={(event) =>
                      setValues({
                        ...values,
                        author_image: event.target.files[0],
                      })
                    }
                  />
                  <FormHelperText>
                    {touched.author_image && errors.author_image}
                  </FormHelperText>
                </FormControl>
              </Stack>

              <LoadingButton
                fullWidth
                sx={{ marginTop: 2 }}
                size="large"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                Save
              </LoadingButton>
              {/* </Form> */}
            </FormikProvider>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default EditBlog;
