import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LinearProgress, Container, Card, CardContent } from "@mui/material";
import DataTable from "react-data-table-component";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import Page from "../../components/Page";
import apiRoutes from "../../routes/apiRoutes";
import CreatedAt from "../../components/CreatedAt";
import { SearchToolBar } from "../../components/_dashboard/user";
import PageHeader from "../../components/PageHeader";
import api from "../../components/helpers/api";
import { swalError } from "../../components/helpers";
import { Link } from "react-router-dom";
import moment from "moment";

const columns = [
  {
    name: "#",
    selector: "id",
    sortable: true,
    maxWidth: "60px",
  },
  {
    name: "IP",
    selector: "ip",
    sortable: true,
  },
  {
    name: "Device",
    selector: "device",
    sortable: true,
  },
  {
    name: "Platform",
    selector: "platform",
    sortable: true,
  },
  {
    name: "Browser",
    selector: "browser",
    sortable: true,
  },
  {
    name: "Language",
    selector: "lang",
    sortable: true,
  },
  {
    name: "User",
    selector: "user",
    cell: (row) =>
      can(permissions.users.view) ? (
        <Link to={`/users/show/${row.user?.id}`}>{row.user?.name}</Link>
      ) : (
        <div>{row.user?.name}</div>
      ),
  },
  {
    name: "First Seen",
    selector: "created_at",
    cell: (row) => CreatedAt(row),
  },
  {
    name: "Last Seen",
    selector: "updated_at",
    cell: (row) => moment(row.updated_at).format("DD/MM/YYYY - h:mm:ss a"),
  },
];

const Visitors = (props) => {
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <SearchToolBar
        filterText={filterText}
        onFilterText={(e) => setFilterText(e.target.value)}
      />
    );
  }, [filterText]);

  useEffect(() => {
    fetchData(1, perPage);
    // eslint-disable-next-line
  }, [perPage, filterText]);

  const fetchData = async (page, perPage) => {
    setLoading(true);

    api
      .get(
        apiRoutes.visitors.all +
          `?page=${page}&per_page=${perPage}&search=${filterText}`
      )
      .then((response) => {
        props.setVisitorsData(response.data.data);
        setTotalRows(response.data.meta.total);
        setPerPage(perPage);
      })
      .catch(() => {
        swalError();
      });

    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchData(page, newPerPage);
  };

  return (
    <Page title="Visitors">
      <PageHeader title="Visitors" />
      <Container>
        <Card>
          <CardContent>
            <DataTable
              title="Visitors Table"
              columns={columns}
              data={props.visitors}
              progressPending={loading}
              pagination
              paginationServer
              highlightOnHover
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              progressComponent={<LinearProgress />}
              persistTableHead
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    visitors: state.visitors.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVisitorsData: (data) =>
      dispatch({
        type: "SET_VISITORS_DATA",
        payload: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Visitors);
