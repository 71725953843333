import {
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import * as Yup from "yup";
import apiRoutes from "../../routes/apiRoutes";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import api from "../../components/helpers/api";
import { connect } from "react-redux";

const Profile = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const LoginSchema = Yup.object().shape({
    // old_password: Yup.string().required("Old Password is required").min(8),
    // password: Yup.string().required("Password is required").min(8),
  });

  const handleShowPasswordConfirmation = () => {
    setShowPasswordConfirmation((show) => !show);
  };
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowOldPassword = () => {
    setShowOldPassword((show) => !show);
  };

  const formik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      password_confirmation: "",
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (data, { setErrors, setValues }) => {
      return api
        .patch(`${apiRoutes.profile.changePassword}`, data)
        .then(() => {
          Swal.fire("Success", "Updated Successfully", "success");
          setValues({
            old_password: "",
            password: "",
            password_confirmation: "",
          });
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ old_password: ["Something went wrong"] });
          }
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  const { user } = props;

  return (
    <Page title="Profile">
      <PageHeader title={`${user?.name} Profile`} />
      <Container>
        <Card>
          <CardHeader title="Change Password" />
          <CardContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    type={showOldPassword ? "text" : "password"}
                    label="Old Password"
                    {...getFieldProps("old_password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleShowOldPassword}
                            edge="end"
                          >
                            <Icon
                              icon={showOldPassword ? eyeFill : eyeOffFill}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.old_password && errors.old_password)}
                    helperText={touched.old_password && errors.old_password}
                  />
                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    {...getFieldProps("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    type={showPasswordConfirmation ? "text" : "password"}
                    label="Password confirmation"
                    {...getFieldProps("password_confirmation")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleShowPasswordConfirmation}
                            edge="end"
                          >
                            <Icon
                              icon={
                                showPasswordConfirmation ? eyeFill : eyeOffFill
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      touched.password_confirmation &&
                        errors.password_confirmation
                    )}
                    helperText={
                      touched.password_confirmation &&
                      errors.password_confirmation
                    }
                  />
                </Stack>

                <LoadingButton
                  fullWidth
                  sx={{ marginTop: 2 }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Change Password
                </LoadingButton>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Profile);
