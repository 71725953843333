import {
  Card,
  CardContent,
  Container,
  FormControl,
  Stack,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import * as Yup from "yup";
import apiRoutes from "../../routes/apiRoutes";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import api from "../../components/helpers/api";
import MultiInput from "../../components/forms/MultiInput";
import { createFormData } from "../../components/helpers";

const CreateCategory = (props) => {
  const history = useHistory();

  const LoginSchema = Yup.object().shape({
    // name: Yup.string().required("Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: {},
      description: {},
      file: "",
      video: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (data, { setErrors }) => {
      return api
        .post(
          `${apiRoutes.categories.create}`,
          createFormData(
            {
              file: [data.file],
              video: [data.video],
            },
            {
              title: data.title,
              description: data.description,
            }
          )
        )
        .then((res) => {
          Swal.fire("Success", "Created Successfully", "success");
          history.push("/categories");
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ title: ["Something went wrong"] });
          }
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    values,
    setValues,
  } = formik;

  const handleSetValue = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  return (
    <Page title="Create Category">
      <PageHeader
        title="Create Category"
        homeLink={can(permissions.categories.view) && "/categories"}
        homeText="Categories"
      />
      <Container>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <MultiInput
                    fullWidth
                    type="text"
                    label="Title"
                    {...getFieldProps("title")}
                    required
                    handleSetValue={handleSetValue}
                    error={Boolean(
                      (touched.title && errors.title) ||
                        Object.keys(errors).filter((error) =>
                          /title/.test(error)
                        ).length
                    )}
                    helperText={touched.title && errors.title}
                  />

                  <MultiInput
                    fullWidth
                    type="text"
                    label="Description"
                    {...getFieldProps("description")}
                    multiline
                    rows={4}
                    handleSetValue={handleSetValue}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />

                  <FormControl error={Boolean(touched.file && errors.file)}>
                    <FormLabel>Image</FormLabel>
                    <input
                      type="file"
                      fullWidth
                      label="File"
                      accept="image/*"
                      onChange={(event) =>
                        setValues({ ...values, file: event.target.files[0] })
                      }
                    />
                    <FormHelperText>
                      {touched.file && errors.file}
                    </FormHelperText>
                  </FormControl>

                  <FormControl error={Boolean(touched.video && errors.video)}>
                    <FormLabel>Promo Video</FormLabel>
                    <input
                      type="file"
                      fullWidth
                      label="Promo Video"
                      accept="video/*"
                      onChange={(event) =>
                        setValues({ ...values, video: event.target.files[0] })
                      }
                    />
                    <FormHelperText>
                      {touched.video && errors.video}
                    </FormHelperText>
                  </FormControl>
                </Stack>

                <LoadingButton
                  fullWidth
                  sx={{ marginTop: 2 }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default CreateCategory;
