import { Card, CardContent, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import PageHeader from "../../../components/PageHeader";
import apiRoutes from "../../../routes/apiRoutes";
import api from "../../../components/helpers/api";
import NavTabs from "../../../components/NavTabs";
import tabs from "../../../components/settings/NavTabsData";
import AboutUs from "../../../components/settings/AboutUs";
import { swalError } from "../../../components/helpers";
import PrivacyPolicy from "../../../components/settings/PrivacyPolicy";
import TermsAndConditions from "../../../components/settings/TermsAndConditions";

const SitePolicies = (props) => {
  const [policies, setPolicies] = useState([]);

  useEffect(() => {
    api
      .get(apiRoutes.sitePolicies.all)
      .then((response) => {
        setPolicies(response.data.data);
      })
      .catch(() => {
        swalError();
      });
  }, []);

  return (
    <Page title={`Site Policies`}>
      <PageHeader title={`Site Policies`} />
      <Container>
        <NavTabs tabs={tabs} />
        <Card>
          <CardContent>
            <AboutUs
              value={
                policies.find((policy) => policy.name === "about_us") ?? {}
              }
            />
            <PrivacyPolicy
              value={
                policies.find((policy) => policy.name === "privacy_policy") ??
                {}
              }
            />
            <TermsAndConditions
              value={
                policies.find(
                  (policy) => policy.name === "terms_and_conditions"
                ) ?? {}
              }
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default SitePolicies;
