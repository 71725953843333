import React from "react";
import upFill from "@iconify/icons-eva/arrow-upward-fill";
import downFill from "@iconify/icons-eva/arrow-downward-fill";
import { Icon } from "@iconify/react";
import { ButtonGroup, IconButton, Tooltip } from "@mui/material";
import { connect } from "react-redux";
import api from "./helpers/api";
import { swalError } from "./helpers";

const Reorder = (props) => {
  const handleUp = () => {
    api
      .post(props.upUrl, { per_page: props.perPage })
      .then((response) => {
        props.setCategoriesData(response.data.data);
      })
      .catch((error) => {
        swalError();
      });
  };
  const handleDown = () => {
    api
      .post(props.downUrl, { per_page: props.perPage })
      .then((response) => {
        props.setCategoriesData(response.data.data);
      })
      .catch(() => {
        swalError();
      });
  };

  return (
    <ButtonGroup variant="outlined" aria-label="outlined button group">
      <Tooltip title="Up" placement="top">
        <IconButton aria-label="delete" onClick={handleUp}>
          <Icon icon={upFill} width={20} height={20} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Down" placement="top">
        <IconButton aria-label="delete" onClick={handleDown}>
          <Icon icon={downFill} width={20} height={20} />
        </IconButton>
      </Tooltip>
    </ButtonGroup>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCategoriesData: (data) =>
      dispatch({
        type: "SET_CATEGORIES_DATA",
        payload: data,
      }),
  };
};

export default connect(null, mapDispatchToProps)(Reorder);
