import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import Swal from "sweetalert2";
import apiRoutes from "../../../routes/apiRoutes";
import MultiInputFile from "../../forms/MultiInputFile";
import api from "../../helpers/api";
import FilesTable from "./FilesTable";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { swalError } from "../../helpers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const FilesCard = (props) => {
  const { lessonId, files, removeFile } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formik = useFormik({
    initialValues: {
      title: {},
      file: {},
    },
    onSubmit: (data, { setErrors }) => {
      const formData = new FormData();
      Object.keys(data.file).forEach((key) =>
        formData.append(`file[${key}]`, data.file[key])
      );
      Object.keys(data.title).forEach((key) =>
        formData.append(`title[${key}]`, data.title[key])
      );
      return api
        .post(`${apiRoutes.files.create}/${lessonId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          props.setLesson(res.data.data);
          handleClose();
          Swal.fire("Success", "Created Successfully", "success");
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ title: ["Something went wrong"] });
          }
        });
    },
  });

  const handleDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const file = files.find((file) => file.id.toString() === draggableId);
    files.splice(source.index, 1);
    files.splice(destination.index, 0, file);
    api
      .patch(`${apiRoutes.files.resort}/${lessonId}`, {
        pdfs: files.map((file) => file.id),
      })
      .then((response) => {
        props.setLesson(response.data.data);
      })
      .catch(() => {
        swalError();
      });
  };

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    // getFieldProps,
    values,
    setValues,
  } = formik;
  const handleSetValue = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  return (
    <Card>
      <CardContent>
        <Typography component="h4" variant="h4">
          Files
        </Typography>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            droppableId={`${lessonId.toString()}`}
            direction="vertical"
          >
            {(provided) => (
              <Box ref={provided.innerRef} {...provided.droppableProps}>
                {files?.map((file, index) => (
                  <FilesTable
                    key={file.id}
                    id={file.id}
                    index={index}
                    title={file.title}
                    paths={file.url}
                    deleteLink={`${apiRoutes.files.delete}/${file.id}`}
                    deleteOneLink={`${apiRoutes.files.deleteOne}/${file.id}`}
                    removeItem={() => removeFile(file.id)}
                    activateUrl={`${apiRoutes.files.activate}/${file.id}`}
                    activeValue={file.active}
                    addTranslationUrl={`${apiRoutes.files.addTranslation}/${file.id}`}
                    fileType="application/pdf"
                    setLesson={props.setLesson}
                  />
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          onClick={handleOpen}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Add new PDF file
        </Button>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add new PDF file
            </Typography>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <MultiInputFile
                    fullWidth
                    type="text"
                    label="Title"
                    fileType="application/pdf"
                    // {...getFieldProps("title")}
                    required
                    handleSetValue={handleSetValue}
                    error={Boolean(
                      (touched.title && errors.title) ||
                        Object.keys(errors).filter((error) =>
                          /title/.test(error)
                        ).length
                    )}
                    helperText={touched.title && errors.title}
                  />
                </Stack>

                <LoadingButton
                  fullWidth
                  sx={{ marginTop: 2 }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Upload
                </LoadingButton>
              </Form>
            </FormikProvider>
          </Box>
        </Modal>
      </CardContent>
    </Card>
  );
};

export default FilesCard;
