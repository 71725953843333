import { Icon } from "@iconify/react";
import { Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import fileFill from "@iconify/icons-eva/file-fill";
import homeFill from "@iconify/icons-eva/home-fill";
import editFill from "@iconify/icons-eva/edit-fill";
import plusFill from "@iconify/icons-eva/plus-fill";

const PageHeader = (props) => {
  const {
    createLink,
    showLink,
    editLink,
    homeLink,
    title,
    createText,
    showText,
    editText,
    homeText,
  } = props;
  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <div>
          {createLink && (
            <Button
              sx={{ marginLeft: 1 }}
              variant="contained"
              color="success"
              component={Link}
              to={createLink}
              startIcon={<Icon icon={plusFill} />}
            >
              {createText}
            </Button>
          )}
          {showLink && (
            <Button
              sx={{ marginLeft: 1 }}
              variant="contained"
              color="primary"
              component={Link}
              to={showLink}
              startIcon={<Icon icon={fileFill} />}
            >
              {showText}
            </Button>
          )}
          {editLink && (
            <Button
              sx={{ marginLeft: 1 }}
              variant="contained"
              color="warning"
              component={Link}
              to={editLink}
              startIcon={<Icon icon={editFill} />}
            >
              {editText}
            </Button>
          )}
          {homeLink && (
            <Button
              sx={{ marginLeft: 1 }}
              variant="contained"
              color="info"
              component={Link}
              to={homeLink}
              startIcon={<Icon icon={homeFill} />}
            >
              {homeText}
            </Button>
          )}
        </div>
      </Stack>
    </Container>
  );
};

export default PageHeader;
