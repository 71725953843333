import { Switch, Route } from "react-router-dom";
import permissions from "../components/permissions/permissions";
import Login from "../pages/auth/Login";
import DashboardApp from "../pages/DashboardApp";
import Admins from "../pages/admins/Admins";
import CreateAdmin from "../pages/admins/CreateAdmin";
import NotFound from "../pages/errors/Page404";
import GuestRoute from "./GuestRoute";
import AuthRoute from "./AuthRoute";
import ForgetPassword from "../pages/auth/ForgetPassword";
import CheckPassword from "../pages/auth/CheckPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import EditAdmin from "../pages/admins/EditAdmin";
import EditAdminPassword from "../pages/admins/EditAdminPassword";
import ShowAdmin from "../pages/admins/ShowAdmin";
import Users from "../pages/users/Users";
import CreateUser from "../pages/users/CreateUser";
import EditUser from "../pages/users/EditUser";
import EditUserPassword from "../pages/users/EditUserPassword";
import ShowUser from "../pages/users/ShowUser";
import Roles from "../pages/roles/Roles";
import CreateRole from "../pages/roles/CreateRole";
import EditRole from "../pages/roles/EditRole";
import ShowRole from "../pages/roles/ShowRole";
import Categories from "../pages/categories/Categories";
import CreateCategory from "../pages/categories/CreateCategory";
import EditCategory from "../pages/categories/EditCategory";
import Courses from "../pages/courses/Courses";
import CreateCourse from "../pages/courses/CreateCourse";
import EditCourse from "../pages/courses/EditCourse";
import ShowCourse from "../pages/courses/ShowCourse";
import Lessons from "../pages/lessons/Lessons";
import CreateLesson from "../pages/lessons/CreateLesson";
import EditLesson from "../pages/lessons/EditLesson";
import ShowLesson from "../pages/lessons/ShowLesson";
import LessonComments from "../pages/lesson_comments/LessonComments";
import Enrollments from "../pages/enrollments/Enrollments";
import Messages from "../pages/messages/Messages";
import ShowMessage from "../pages/messages/ShowMessage";
import Visitors from "../pages/visitors/Visitors";
import SocialMedia from "../pages/settings/social_media/SocialMedia";
import CreateSocialMedia from "../pages/settings/social_media/CreateSocialMedia";
import EditSocialMedia from "../pages/settings/social_media/EditSocialMedia";
import SitePolicies from "../pages/settings/site_policies/SitePolicies";
import FAQ from "../pages/settings/faq/FAQ";
import CreateFAQ from "../pages/settings/faq/CreateFAQ";
import EditFAQ from "../pages/settings/faq/EditFAQ";
import ShowFAQ from "../pages/settings/faq/ShowFAQ";
import Profile from "../pages/profile/Profile";
import Blog from "../pages/blog/Blog";
import CreateBlog from "../pages/blog/CreateBlog";
import BlogComments from "../pages/blog_comments/BlogComments";
import EditBlog from "../pages/blog/EditBlog";
import ShowBlog from "../pages/blog/ShowBlog";

// ----------------------------------------------------------------------

const Router = () => {
  return (
    <Switch>
      <GuestRoute exact path="/" component={Login} />
      <GuestRoute exact path="/login" component={Login} />
      <GuestRoute path="/forget/password" component={ForgetPassword} />
      <GuestRoute path="/check/password" component={CheckPassword} />
      <GuestRoute path="/reset/password" component={ResetPassword} />

      <AuthRoute exact path="/home" component={DashboardApp} />
      <AuthRoute exact path="/profile" component={Profile} />

      <AuthRoute
        exact
        path="/admins"
        component={Admins}
        permission={permissions.admins.view}
      />
      <AuthRoute
        exact
        path="/admins/create"
        component={CreateAdmin}
        permission={permissions.admins.create}
      />
      <AuthRoute
        exact
        path="/admins/edit/:id"
        component={EditAdmin}
        permission={permissions.admins.update}
      />
      <AuthRoute
        exact
        path="/admins/password/edit/:id"
        component={EditAdminPassword}
        permission={permissions.admins.update}
      />
      <AuthRoute
        exact
        path="/admins/show/:id"
        component={ShowAdmin}
        permission={permissions.admins.view}
      />

      <AuthRoute
        exact
        path="/roles"
        component={Roles}
        permission={permissions.roles.view}
      />
      <AuthRoute
        exact
        path="/roles/create"
        component={CreateRole}
        permission={permissions.roles.create}
      />
      <AuthRoute
        exact
        path="/roles/edit/:id"
        component={EditRole}
        permission={permissions.roles.update}
      />
      <AuthRoute
        exact
        path="/roles/show/:id"
        component={ShowRole}
        permission={permissions.roles.view}
      />

      <AuthRoute
        exact
        path="/users"
        component={Users}
        permission={permissions.users.view}
      />
      <AuthRoute
        exact
        path="/users/create"
        component={CreateUser}
        permission={permissions.users.create}
      />
      <AuthRoute
        exact
        path="/users/edit/:id"
        component={EditUser}
        permission={permissions.users.update}
      />
      <AuthRoute
        exact
        path="/users/password/edit/:id"
        component={EditUserPassword}
        permission={permissions.users.update}
      />
      <AuthRoute
        exact
        path="/users/show/:id"
        component={ShowUser}
        permission={permissions.users.view}
      />

      <AuthRoute
        exact
        path="/categories"
        component={Categories}
        permission={permissions.categories.view}
      />
      <AuthRoute
        exact
        path="/categories/create"
        component={CreateCategory}
        permission={permissions.categories.create}
      />
      <AuthRoute
        exact
        path="/categories/edit/:id"
        component={EditCategory}
        permission={permissions.categories.update}
      />

      <AuthRoute
        exact
        path="/courses/:id"
        component={Courses}
        permission={permissions.courses.view}
      />
      <AuthRoute
        exact
        path="/courses/create/:id"
        component={CreateCourse}
        permission={permissions.courses.create}
      />
      <AuthRoute
        exact
        path="/courses/edit/:id"
        component={EditCourse}
        permission={permissions.courses.update}
      />
      <AuthRoute
        exact
        path="/courses/show/:id"
        component={ShowCourse}
        permission={permissions.courses.view}
      />

      <AuthRoute
        exact
        path="/lessons/:id"
        component={Lessons}
        permission={permissions.lessons.view}
      />
      <AuthRoute
        exact
        path="/lessons/create/:id"
        component={CreateLesson}
        permission={permissions.lessons.create}
      />
      <AuthRoute
        exact
        path="/lessons/edit/:id"
        component={EditLesson}
        permission={permissions.lessons.update}
      />
      <AuthRoute
        exact
        path="/lessons/show/:id"
        component={ShowLesson}
        permission={permissions.lessons.view}
      />

      <AuthRoute
        exact
        path="/comments"
        component={LessonComments}
        permission={permissions.lesson_comments.view}
      />

      <AuthRoute
        exact
        path="/blog"
        component={Blog}
        permission={permissions.blog.view}
      />
      <AuthRoute
        exact
        path="/blog/create"
        component={CreateBlog}
        permission={permissions.blog.create}
      />
      <AuthRoute
        exact
        path="/blog/edit/:id"
        component={EditBlog}
        permission={permissions.blog.update}
      />
      <AuthRoute
        exact
        path="/blog/show/:id"
        component={ShowBlog}
        permission={permissions.blog.view}
      />

      <AuthRoute
        exact
        path="/blog_comments"
        component={BlogComments}
        permission={permissions.blog_comments.view}
      />

      <AuthRoute
        exact
        path="/enrollments"
        component={Enrollments}
        permission={permissions.enrollments.view}
      />

      <AuthRoute
        exact
        path="/visitors"
        component={Visitors}
        permission={permissions.visitors.view}
      />

      <AuthRoute
        exact
        path="/messages"
        component={Messages}
        permission={permissions.messages.view}
      />
      <AuthRoute
        exact
        path="/messages/show/:id"
        component={ShowMessage}
        permission={permissions.messages.view}
      />

      <AuthRoute
        exact
        path="/social_media"
        component={SocialMedia}
        permission={permissions.settings.view}
      />
      <AuthRoute
        exact
        path="/social_media/create"
        component={CreateSocialMedia}
        permission={permissions.settings.create}
      />
      <AuthRoute
        exact
        path="/social_media/edit/:id"
        component={EditSocialMedia}
        permission={permissions.settings.update}
      />

      <AuthRoute
        exact
        path="/faq"
        component={FAQ}
        permission={permissions.settings.view}
      />
      <AuthRoute
        exact
        path="/faq/create"
        component={CreateFAQ}
        permission={permissions.settings.create}
      />
      <AuthRoute
        exact
        path="/faq/edit/:id"
        component={EditFAQ}
        permission={permissions.settings.update}
      />
      <AuthRoute
        exact
        path="/faq/show/:id"
        component={ShowFAQ}
        permission={permissions.settings.view}
      />

      <AuthRoute
        exact
        path="/site_policies"
        component={SitePolicies}
        permission={permissions.settings.view}
      />

      <Route component={NotFound} />
    </Switch>
  );
};
export default Router;
