import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import languages from "./languages";

const MultiInput = (props) => {
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const [inputValue, setInputValue] = useState(props.value ?? {});

  useEffect(() => {
    setInputValue(props.value ?? {});
  }, [props.value]);

  const handelSelectChange = (event) => {
    setCurrentLanguage(event.target.value);
    setInputValue({
      ...inputValue,
      [event.target.value]: inputValue[event.target.value] ?? "",
    });
  };
  const handelInputChange = (event) => {
    let newValue = { ...inputValue, [currentLanguage]: event.target.value };
    newValue = Object.fromEntries(
      Object.entries(newValue).filter(([_, v]) => v !== "")
    );
    setInputValue(newValue);
    props.handleSetValue(props.name, newValue);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Language</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentLanguage}
            label="Language"
            onChange={handelSelectChange}
          >
            {languages.map((option, idx) => (
              <MenuItem value={option.lang} key={idx}>
                {option.language}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={10}>
        <TextField
          {...props}
          value={inputValue[currentLanguage] ?? ""}
          onChange={handelInputChange}
        />
      </Grid>
    </Grid>
  );
};

export default MultiInput;
