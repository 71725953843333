import {
  Autocomplete,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import PageHeader from "../../../components/PageHeader";
import * as Yup from "yup";
import apiRoutes from "../../../routes/apiRoutes";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { can } from "../../../components/helpers/Authorization";
import permissions from "../../../components/permissions/permissions";
import api from "../../../components/helpers/api";
import MultiInput from "../../../components/forms/MultiInput";
import { swalError } from "../../../components/helpers";
import { Icon } from "@iconify/react";
import { Box } from "@mui/system";
import socialmedia from "../../../components/data/socialmedia";

const EditSocialMedia = (props) => {
  const history = useHistory();
  const socialMediaId = props.match.params.id;
  const [socialMedia, setSocialMedia] = useState({});

  const LoginSchema = Yup.object().shape({
    // name: Yup.string().required("Name is required"),
  });

  useEffect(() => {
    _getSocialMedia();
    // eslint-disable-next-line
  }, []);

  const _getSocialMedia = () => {
    api
      .get(apiRoutes.socialMedia.show + "/" + socialMediaId)
      .then((response) => {
        const data = response.data.data;
        setSocialMedia(data);
      })
      .catch(() => {
        swalError();
      });
  };

  const formik = useFormik({
    initialValues: {
      title: socialMedia.title,
      type: socialMedia.type ?? "",
      value: socialMedia.value,
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (data, { setErrors }) => {
      return api
        .patch(`${apiRoutes.socialMedia.update}/${socialMedia.id}`, data)
        .then(() => {
          Swal.fire("Success", "Updated Successfully", "success");
          history.push(`/social_media`);
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ name: ["Something went wrong"] });
          }
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    values,
    setValues,
  } = formik;

  const handleSetValue = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  return (
    <Page title="Edit Social Media">
      <PageHeader
        title="Edit Social Media"
        homeLink={can(permissions.settings.view) && `/social_media`}
        homeText="Social Media"
        createLink={can(permissions.settings.create) && `/social_media/create`}
        createText="Create"
      />
      <Container>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <MultiInput
                    fullWidth
                    type="text"
                    label="Title"
                    {...getFieldProps("title")}
                    required
                    handleSetValue={handleSetValue}
                    error={Boolean(
                      (touched.title && errors.title) ||
                        Object.keys(errors).filter((error) =>
                          /title/.test(error)
                        ).length
                    )}
                    helperText={touched.title && errors.title}
                  />

                  <FormControl error={Boolean(touched.type && errors.type)}>
                    <Autocomplete
                      id="type-select-demo"
                      options={socialmedia}
                      autoHighlight
                      autoSelect
                      getOptionLabel={(option) => {
                        if (option.hasOwnProperty("name")) {
                          return option.name;
                        }
                        return option;
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option.name === value;
                      }}
                      value={values.type ?? ""}
                      inputValue={values.type ?? ""}
                      onInputChange={(event, newInputValue) => {
                        setValues({ ...values, type: newInputValue });
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          <Icon
                            color={option.color}
                            icon={option.icon}
                            width={22}
                            height={22}
                          />
                          {option.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Type"
                          fullWidth
                          required
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    <FormHelperText>
                      {touched.type && errors.type}
                    </FormHelperText>
                  </FormControl>

                  <TextField
                    fullWidth
                    autoComplete="name"
                    type="text"
                    label="Url"
                    required
                    {...getFieldProps("value")}
                    error={Boolean(touched.value && errors.value)}
                    helperText={touched.value && errors.value}
                  />
                </Stack>

                <LoadingButton
                  fullWidth
                  sx={{ marginTop: 2 }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default EditSocialMedia;
