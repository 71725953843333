import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LinearProgress, Container, Card, CardContent } from "@mui/material";
import DataTable from "react-data-table-component";
import { can } from "../../../components/helpers/Authorization";
import permissions from "../../../components/permissions/permissions";
import Page from "../../../components/Page";
import apiRoutes from "../../../routes/apiRoutes";
import store from "../../../store";
import CreatedAt from "../../../components/CreatedAt";
import { SearchToolBar, MoreMenu } from "../../../components/_dashboard/user";
import PageHeader from "../../../components/PageHeader";
import api from "../../../components/helpers/api";
import { swalError } from "../../../components/helpers";
import NavTabs from "../../../components/NavTabs";
import tabs from "../../../components/settings/NavTabsData";
import { Icon } from "@iconify/react";
import socialmedia from "../../../components/data/socialmedia";

const columns = [
  {
    name: "#",
    selector: "id",
    sortable: true,
    maxWidth: "60px",
  },
  {
    name: "Title",
    selector: "title",
    sortable: true,
    cell: (row) => (
      <div>
        {Object.keys(row.title).map((key) => (
          <div key={key}>
            {key.toLocaleUpperCase()}: {row.title[key]}
          </div>
        ))}
      </div>
    ),
  },
  {
    name: "Type",
    selector: "type",
    sortable: true,
    cell: (row) => {
      const icon = socialmedia.find((social) => social.name === row.type);
      return icon ? (
        <Icon color={icon.color} icon={icon.icon} width={22} height={22} />
      ) : (
        row.type
      );
    },
  },
  {
    name: "Url",
    selector: "value",
    sortable: true,
  },
  {
    name: "Created At",
    selector: "created_at",
    cell: (row) => CreatedAt(row),
  },
  {
    name: "Controls",
    selector: "actions",
    maxWidth: "60px",
    cell: (row) => (
      <MoreMenu
        editLink={
          can(permissions.settings.update) && `/social_media/edit/${row.id}`
        }
        removeItem={() => removeItem(row.id)}
        deleteLink={
          can(permissions.settings.delete) &&
          apiRoutes.socialMedia.delete + "/" + row.id
        }
      />
    ),
  },
];

const removeItem = (id) => {
  const data = store.getState().socialMedia.data.filter((admin) => {
    return admin.id !== id;
  });
  store.dispatch({ type: "SET_SOCIAL_MEDIA_DATA", payload: data });
};

const SocialMedia = (props) => {
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <SearchToolBar
        filterText={filterText}
        onFilterText={(e) => setFilterText(e.target.value)}
      />
    );
  }, [filterText]);

  useEffect(() => {
    fetchData(1, perPage);
    // eslint-disable-next-line
  }, [perPage, filterText]);

  const fetchData = async (page, perPage) => {
    setLoading(true);

    api
      .get(
        apiRoutes.socialMedia.all +
          `?page=${page}&per_page=${perPage}&search=${filterText}`
      )
      .then((response) => {
        props.setSocialMediaData(response.data.data);
        setTotalRows(response.data.meta.total);
        setPerPage(perPage);
      })
      .catch(() => {
        swalError();
      });

    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchData(page, newPerPage);
  };

  return (
    <Page title="Social Media">
      <PageHeader
        title="Settings"
        createLink={can(permissions.settings.create) && "/social_media/create"}
        createText="Create Social Media"
      />
      <Container>
        <NavTabs tabs={tabs} />
        <Card>
          <CardContent>
            <DataTable
              title="Social Media Table"
              columns={columns}
              data={props.socialMedia}
              progressPending={loading}
              pagination
              paginationServer
              highlightOnHover
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              progressComponent={<LinearProgress />}
              persistTableHead
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    socialMedia: state.settings.socialMedia,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSocialMediaData: (data) =>
      dispatch({
        type: "SET_SOCIAL_MEDIA_DATA",
        payload: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialMedia);
