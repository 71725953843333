import { Card, CardContent, Container, Stack } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import * as Yup from "yup";
import apiRoutes from "../../routes/apiRoutes";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { can } from "../../components/helpers/Authorization";
import permissions from "../../components/permissions/permissions";
import api from "../../components/helpers/api";
import MultiInput from "../../components/forms/MultiInput";
import { getFirstValue, swalError } from "../../components/helpers";

const CreateLesson = (props) => {
  const history = useHistory();
  const courseId = props.match.params.id;
  const [course, setCourse] = useState({});

  const LoginSchema = Yup.object().shape({});

  useEffect(() => {
    _getCourse();
    // eslint-disable-next-line
  }, []);

  const _getCourse = () => {
    api
      .get(apiRoutes.courses.show + "/" + courseId)
      .then((response) => {
        const data = response.data.data;
        setCourse(data);
      })
      .catch(() => {
        swalError();
      });
  };

  const formik = useFormik({
    initialValues: {
      title: {},
    },
    validationSchema: LoginSchema,
    onSubmit: (data, { setErrors }) => {
      return api
        .post(`${apiRoutes.lessons.create}/${courseId}`, data)
        .then((res) => {
          Swal.fire("Success", "Created Successfully", "success");
          history.push(`/lessons/${courseId}`);
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ title: ["Something went wrong"] });
          }
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    values,
    setValues,
  } = formik;

  const handleSetValue = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  return (
    <Page title={`Create Lesson for ${getFirstValue(course.title)}`}>
      <PageHeader
        title={`Create Lesson for ${getFirstValue(course.title)}`}
        homeLink={can(permissions.lessons.view) && `/lessons/${courseId}`}
        homeText="Lessons"
      />
      <Container>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <MultiInput
                    fullWidth
                    type="text"
                    label="Title"
                    {...getFieldProps("title")}
                    required
                    handleSetValue={handleSetValue}
                    error={Boolean(
                      (touched.title && errors.title) ||
                        Object.keys(errors).filter((error) =>
                          /title/.test(error)
                        ).length
                    )}
                    helperText={touched.title && errors.title}
                  />
                </Stack>

                <LoadingButton
                  fullWidth
                  sx={{ marginTop: 2 }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default CreateLesson;
