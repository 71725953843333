const socialmedia = [
  { name: "facebook", icon: "fa-brands:facebook-f", color: "#1877F2" },
  { name: "whatsapp", icon: "fa-brands:whatsapp", color: "#25D366" },
  { name: "youtube", icon: "fa-brands:youtube", color: "#CD201F" },
  { name: "instagram", icon: "fa-brands:instagram", color: "#E4405F" },
  { name: "twitter", icon: "fa-brands:twitter", color: "#1DA1F2" },
  { name: "linkedin", icon: "fa-brands:linkedin", color: "#0A66C2" },
  { name: "email", icon: "eva:at-fill", color: "#999" },
  { name: "snapchat", icon: "fa-brands:snapchat", color: "#FFFC00" },
  { name: "soundcloud", icon: "fa-brands:soundcloud", color: "#ff8800" },
  { name: "soundcloud", icon: "fa-brands:soundcloud", color: "#ff8800" },
  { name: "telegram", icon: "fa-brands:telegram", color: "#0088cc" },
  { name: "patreon", icon: "fa-brands:patreon", color: "#f96854" },
];

export default socialmedia;
