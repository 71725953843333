import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import languages from "./forms/languages";
import { Card } from "@mui/material";

const MultiLanguageTable = (props) => {
  const { content, title, enableHTML } = props;
  if (!content) {
    return null;
  }
  return (
    <Card>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "150px" }}>Languages</TableCell>
              <TableCell>{title}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(content).map((key, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {languages.find((lang) => lang.lang === key).language}
                </TableCell>
                {enableHTML ? (
                  <TableCell
                    style={{ whiteSpace: "pre-wrap" }}
                    dangerouslySetInnerHTML={{ __html: content[key] }}
                  />
                ) : (
                  <TableCell style={{ whiteSpace: "pre-wrap" }}>
                    {content[key]}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default MultiLanguageTable;
