import React from "react";
import { Draggable } from "react-beautiful-dnd";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import { getFirstValue, swalError } from "../../helpers";
import { MoreMenu } from "../user";
import { can } from "../../helpers/Authorization";
import permissions from "../../permissions/permissions";
import apiRoutes from "../../../routes/apiRoutes";
import api from "../../helpers/api";
import store from "../../../store";
import moment from "moment";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import checkmarkFill from "@iconify/icons-eva/checkmark-circle-2-fill";
import closeFill from "@iconify/icons-eva/close-circle-fill";
import Activate from "../../Activate";
import { Box } from "@mui/system";
import { useHistory } from 'react-router-dom';

const LessonItem = ({ lesson, index }) => {
  const { id, title, published_at, paid } = lesson;
  const history = useHistory()

  const removeItem = (id) => {
    const data = store.getState().lessons.data.filter((lesson) => {
      return lesson.id !== id;
    });
    store.dispatch({ type: "SET_LESSONS_DATA", payload: data });
  };

  const handlePublish = () => {
    if (window.confirm("Are you sure?")) {
      api
        .patch(`${apiRoutes.lessons.publish}/${id}`)
        .then((response) => {
          const lessons = store.getState().lessons.data.map((course) => {
            if (course.id === id) {
              return {
                ...course,
                published_at: course.published_at ? null : moment(),
              };
            }
            return course;
          });
          store.dispatch({ type: "SET_LESSONS_DATA", payload: lessons });
        })
        .catch(() => {
          swalError();
        });
    }
  };
  return (
    <Draggable draggableId={`${id.toString()}`} index={index}>
      {(provided) => (
        <ListItem
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          sx={{ borderBottom: "1px solid #eee" }}
        >
          <ListItemText
            component={Link}
            to={`/lessons/show/${id}`}
            id="switch-list-label"
            primary={getFirstValue(title)}
            onClick={()=> history.push(`/lessons/show/${id}`)}
            sx={{ cursor: "pointer" }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: published_at ? "green" : "grey.500",
            }}
          >
            <Box
              component={Icon}
              icon={checkmarkFill}
              sx={{ width: 16, height: 16, mr: 0.5 }}
            />
            <Typography variant="caption">
              {published_at ? `Published` : "Not published"}
            </Typography>
          </Box>
          &nbsp; Paid:
          <Activate
            url={apiRoutes.lessons.pay + "/" + id}
            checked={paid}
            activeTitle="Paid"
            inactiveTitle="Free"
          />
          <MoreMenu
            editLink={can(permissions.lessons.update) && `/lessons/edit/${id}`}
            showLink={can(permissions.lessons.view) && `/lessons/show/${id}`}
            removeItem={() => removeItem(id)}
            deleteLink={
              can(permissions.lessons.delete) &&
              apiRoutes.lessons.delete + "/" + id
            }
          >
            {can(permissions.lessons.update) && (
              <MenuItem
                sx={{ color: "text.secondary" }}
                onClick={handlePublish}
              >
                <ListItemIcon>
                  <Icon
                    icon={published_at ? closeFill : checkmarkFill}
                    width={24}
                    height={24}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={published_at ? "Unpublish" : "Publish"}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>
            )}
          </MoreMenu>
        </ListItem>
      )}
    </Draggable>
  );
};

export default LessonItem;
